import { mapGetters, mapMutations } from "vuex";
import Cookie from "js-cookie";
import store from "../vuex/store";
import userAndCart from "./user-and-cart.js";
import SignIn from "../components/authentication/SignIn.vue";
import SignUp from "../components/authentication/SignUp.vue";
import signOut from "./sign_out";

export default {
  store,

  mixins: [userAndCart, signOut],

  components: {
    SignIn,
    SignUp,
  },

  data() {
    return {
      isSignInView: window._PR ? window._PR.signin : false,
      currentModalView: "signin",
      carriedOverMessage: window._PR
        ? window._PR.carried_over_message
        : undefined,
    };
  },

  computed: {
    ...mapGetters(["me", "localCart", "isLoggedIn"]),

    hasLoginCookie() {
      return Cookie.get("logged_in") !== undefined;
    },

    hasUserSessionStorage() {
      return sessionStorage.getItem("user") !== null;
    },

    // hasProxifyCookie() {
    //   return this.doesHttpOnlyCookieExist("prismatic_powders_proxify");
    // },
  },

  mounted() {
    const ssoParam = new URLSearchParams(window.location.search);

    // !IMPORTANT: 👇 this handles setting the user login for each page.
    if (ssoParam.has("sign-in")) {
      this.getUser();
    } else if (
      !this.hasLoginCookie ||
      !this.hasUserSessionStorage
    ) {
      this.signOut(false);
    } else if (this.hasLoginCookie && this.hasUserSessionStorage) {
      this.getUser();
    } else {
      this.setIsLoggedIn(true);
    }

    this.getCart();

    // Safari does not cooperate with Vue and is removing the session storage which forces us to fetch the user endpoint on every page load.
    let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    if (isSafari) {
      this.getUser();
    }

    if (
      this.currentModalView &&
      this.currentModalView === "delegateRegistration"
    ) {
      $("#logInLink").trigger("click");
    }

    if (this.isResetView) {
      this.currentModalView = "reset";
      $("#logInLink").trigger("click");
    }

    if (this.isSignInView) {
      $("#logInLink").trigger("click");
    }
  },

  methods: {
    ...mapMutations(["setIsLoggedIn", "setLocalCart"]),

    /**
     * @function doesHttpOnlyCookieExist
     * @desc checks if the proxify login cookie exists
     * @param {string}
     * @returns {boolean}
     */
    doesHttpOnlyCookieExist(cookiename) {
      var d = new Date();
      d.setTime(d.getTime() + 1000);
      var expires = "expires=" + d.toUTCString();

      document.cookie = cookiename + "=new_value;path=/;" + expires;
      return document.cookie.indexOf(cookiename + "=") == -1;
    },

    closeLoginModal() {
      this.currentModalView = "signin";
    },

    clearAndChangeTo(view) {
      this.carriedOverMessage = null;
      window.history.replaceState("", "", "/");
      this.currentModalView = view;
    },
  },
};
