<template>
  <section id="featured-intro" class="row">
    <div>
      <div id="intro-content" class="col-md-8 col-md-offset-2">
        <h1 class="title-underline">Any Color, Any Quantity, Anywhere</h1>
        <p>Welcome to <span v-html="$root.domainTrademark"></span>, the premier manufacturer of vibrant and high-quality powder coating
          finishes. As the industry leader in powder coating powders, we offer over 6,500 colors and finishes designed
          to bring your creative visions to life. Whether you're a manufacturer, coating facility, or a passionate DIY
          enthusiast, our wide selection of powders and finishes, as well as our unwavering commitment to excellence,
          ensure that your projects will stand out from the rest. With unrivaled color selection, color matching
          services, and exceptional customer support, {{ $root.domainName }} is your trusted partner in bringing your
          visions to vivid reality. Explore our vast selection today and make a lasting impression with {{
            $root.domainName }}, where creativity and quality converge.</p>
        <a href="/shop/powder-coating-colors" class="shop-btn btn btn-black">Shop Colors</a>
      </div>
      <div class="intro-cta col-xs-12">
        <div class="col-sm-12 col-md-6">
          <a href="/gallery">
            <div class="shadow-box text-center">
              <h3>Browse Powder Coating Projects</h3>
              <img src="/img/home-project-gallery.jpg" title="Browse Powder Coating Projects"
                alt="Browse Powder Coating Projects" />
            </div>
          </a>
        </div>
        <div class="col-sm-12 col-md-6">
          <a href="/find-applicator">
            <div class="shadow-box text-center">
              <h3>Find An Applicator Nearby</h3>
              <img src="/img/home-find-an-applicator.jpg" title="Find An Applicator Nearby"
                alt="Find An Applicator Nearby" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'featured-intro',
}
</script>

<style scoped lang="scss">
#featured-intro {
  padding: 0 3% 5% 3%;

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  #intro-content {
    padding: 2% 0 5% 0;
  }

  .placeholder {
    margin: 10px;

    .img-placeholder {
      height: 250px;
      padding: 3%;
    }
  }

  .shadow-box {
    transition: all 200ms ease-in-out;

    h3 {
      z-index: 1;
    }
  }

  .shadow-box:hover {
    opacity: 1;
    box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
  }

  .intro-cta {
    padding: 0 3%;

    .col-sm-12 {
      margin-top: 10px;
    }

    h3 {
      position: absolute;
      color: #fbfbfb;
      border-radius: 6px;
      background-color: #3982c499;
      border: 2px solid #3982c480;
      padding: 2%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      font-size: 1.6rem;
      margin: 0;
      font-weight: 700;
    }

    img {
      border-radius: 6px;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .shop-btn {
      width: 100%;
    }
  }
}
</style>
