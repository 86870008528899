var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",attrs:{"id":"footer-content"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"footer-divider col-md-12"},[_c('div',{staticClass:"divider-logo"},[_c('prisLogo')],1),_vm._v(" "),_c('hr')]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 footlinks"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-lg-2 desktop-only"},[_c('h3',[_vm._v("Customer Service")]),_vm._v(" "),_c('ul',[(_vm.$root.isLoggedIn)?_c('li',[_c('a',{attrs:{"href":"/order-history"}},[_vm._v("Check order status")])]):_vm._e(),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)])]),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-6 col-lg-3 col-lg-offset-1"},[_vm._m(7),_vm._v(" "),_c('h3',[_vm._v("\n            "+_vm._s(_vm.$root.domainName)+" Newsletter"),_c('br'),_c('small',[_vm._v("Subscribe to receive exclusive promos and discounts!")])]),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9)]),_vm._v(" "),_vm._m(10)])]),_vm._v(" "),_vm._m(11),_vm._v(" "),_c('div',{staticClass:"col-xs-12 copyright"},[_c('div',{staticClass:"copyright-details"},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear())+"\n          "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$root.domainTrademark)}}),_vm._v(". All Rights Reserved. ")]),_c('a',{attrs:{"href":"/terms-and-policies"}},[_vm._v("Terms of Use")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/terms-and-policies?to=returns"}},[_vm._v("Returns")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/terms-and-policies?to=shipping"}},[_vm._v("Shipping & Delivery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/contact-us"}},[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/terms-and-policies"}},[_vm._v("Terms & Policies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-2 desktop-only"},[_c('h3',[_vm._v("About Us")]),_vm._v(" "),_c('ul',[_c('li',[_c('a',{attrs:{"href":"/about-us"}},[_vm._v("Who we are")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/career"}},[_vm._v("Careers")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/testimonials"}},[_vm._v("Customer Testimonials")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/news"}},[_vm._v("Announcement Hub")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-2 desktop-only"},[_c('h3',[_vm._v("Resources")]),_vm._v(" "),_c('ul',[_c('li',[_c('a',{attrs:{"href":"/shop/powder-coating-colors"}},[_vm._v("Shop Powder Coating Colors")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"shop/color-match"}},[_vm._v("Color Match")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/gallery"}},[_vm._v("Applicator Projects")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/find-applicator"}},[_vm._v("Find an Applicator Near You")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/knowledge-base"}},[_vm._v("Knowledge Base")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/documents"}},[_vm._v("Documents & Downloads")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"/videos"}},[_vm._v("Videos")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-2 desktop-only"},[_c('h3',[_vm._v("We're Worldwide")]),_vm._v(" "),_c('ul',[_c('li',[_c('a',{attrs:{"href":"/location"}},[_vm._v("See Locations")])])]),_vm._v(" "),_c('h3',[_vm._v("Innovations of NIC")]),_vm._v(" "),_c('ul',[_c('li',[_c('a',{attrs:{"rel":"noopener","href":"https://www.cerakote.com/?utm_source=prismatic&utm_medium=referral&utm_campaign=&utm_content=footer-link","target":"_blank"}},[_vm._v("Cerakote"),_c('sup',[_vm._v("®")])])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"logo-nic",attrs:{"rel":"noopener","href":"http://www.nicindustries.com/","target":"_blank"}},[_vm._v("NIC Industries Inc."),_c('sup',[_vm._v("®")])])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"rel":"noopener","href":"https://cerakoteceramics.com/?utm_source=prismatic&utm_medium=referral&utm_campaign=&utm_content=footer-link","target":"_blank"}},[_vm._v("Performance Materials")])])]),_vm._v(" "),_c('h3',[_vm._v("\n            We're Always Looking To Improve"),_c('br'),_c('small',[_vm._v("Tell us what you love or what we need to fix.")])]),_vm._v(" "),_c('ul',[_c('li',[_c('a',{staticClass:"btn btn-alt-outline",attrs:{"href":"mailto:info@prismaticpowders.com"}},[_vm._v("Give Feedback")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_vm._v("\n            Need Help? Call us at:\n            "),_c('a',{attrs:{"href":"tel:1-866-774-7628"}},[_vm._v("1-866-774-7628")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mc_embed_signup-footer"}},[_c('form',{staticClass:"validate",attrs:{"action":"https://nicindustries.us1.list-manage.com/subscribe/post?u=99fe9d627854feb5c257b8347&id=9ee76ca792","method":"post","id":"mc-embedded-subscribe-form-footer","name":"mc-embedded-subscribe-form","target":"_blank","novalidate":""}},[_c('div',{attrs:{"id":"mc_embed_signup_scroll-footer"}},[_c('div',{staticClass:"mc-field-group form-group"},[_c('input',{staticClass:"required email form-control",attrs:{"type":"email","placeholder":"* Email Address","name":"EMAIL","id":"mce-EMAIL-footer"}})]),_vm._v(" "),_c('div',{staticClass:"clear",attrs:{"id":"mce-responses-footer"}},[_c('div',{staticClass:"response",staticStyle:{"display":"none"},attrs:{"id":"mce-error-response-footer"}}),_vm._v(" "),_c('div',{staticClass:"response",staticStyle:{"display":"none"},attrs:{"id":"mce-success-response-footer"}})]),_vm._v(" "),_c('div',{staticStyle:{"position":"absolute","left":"-5000px"},attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"text","name":"b_99fe9d627854feb5c257b8347_9ee76ca792","tabindex":"-1","value":""}})]),_vm._v(" "),_c('div',{staticClass:"clear form-group"},[_c('input',{staticClass:"button btn btn-blue-subscribe",staticStyle:{"width":"100%"},attrs:{"type":"submit","value":"Subscribe","name":"subscribe","id":"mc-embedded-subscribe-footer"}})]),_vm._v(" "),_c('div',{staticClass:"indicates-required"},[_c('span',{staticClass:"asterisk"},[_vm._v("* indicates required")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm-links"},[_c('h6',[_vm._v("Got Social? Follow Us")]),_vm._v(" "),_c('div',[_c('a',{attrs:{"rel":"noopener","href":"https://www.facebook.com/PrismaticPowders/"}},[_c('i',{staticClass:"fa fa-facebook fa-lg",staticStyle:{"margin":"4px"},attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('a',{attrs:{"rel":"noopener","href":"https://www.youtube.com/user/PrismaticPowders"}},[_c('i',{staticClass:"fa fa-youtube fa-lg",staticStyle:{"margin":"4px"},attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('a',{attrs:{"rel":"noopener","href":"https://twitter.com/prismaticpowder"}},[_c('i',{staticClass:"fa fa-twitter fa-lg",staticStyle:{"margin":"4px"},attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('a',{attrs:{"rel":"noopener","href":"https://www.pinterest.com/prismaticpowdrs/prismatic-powders-projects/"}},[_c('i',{staticClass:"fa fa-pinterest fa-lg",staticStyle:{"margin":"4px"},attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('a',{attrs:{"rel":"noopener","href":"https://www.instagram.com/prismaticpowders/?hl=en"}},[_c('i',{staticClass:"fa fa-instagram fa-lg",staticStyle:{"margin":"4px"},attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('a',{attrs:{"rel":"noopener","href":"mailto:info@prismaticpowders.com?subject=Prismatic Powders&body=Check out this website: https://www.prismaticpowders.com/"}},[_c('i',{staticClass:"fa fa-envelope-o fa-lg",staticStyle:{"margin":"4px"},attrs:{"aria-hidden":"true"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 col-sm-6 col-lg-3 mobile-only"},[_c('h3',{staticClass:"tablet-only"},[_vm._v("International")]),_vm._v(" "),_c('ul',{staticClass:"tablet-only"},[_c('li',[_c('a',{staticClass:"globe",attrs:{"href":"/location"}},[_vm._v("Locations")])])]),_vm._v(" "),_c('br',{staticClass:"tablet-only"}),_vm._v(" "),_c('h3',{staticClass:"tablet-only"},[_vm._v("Our other divisions")]),_vm._v(" "),_c('ul',{staticClass:"tablet-only"},[_c('li',[_c('a',{attrs:{"rel":"noopener","href":"https://www.cerakote.com/?utm_source=prismatic&utm_medium=referral&utm_campaign=&utm_content=footer-link","target":"_blank"}},[_vm._v("Cerakote"),_c('sup',[_vm._v("®")])])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"rel":"noopener","href":"https://cerakoteceramics.com/?utm_source=prismatic&utm_medium=referral&utm_content=footer-link","target":"_blank"}},[_vm._v("Performance Materials")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('hr')])
}]

export { render, staticRenderFns }