import { mapGetters, mapMutations } from "vuex";
import Cookie from "js-cookie";

export default {
  data() {
    return {
      loadingUserData: false,
      loadingCartData: false,
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn", "hasLocalCart"]),
  },

  methods: {
    ...mapMutations([
      "setMe",
      "setLocalCart",
      "setIsLoggedIn",
      "setHasLocalCart",
    ]),

    /**
     * Fetches user data and saves to vuex.
     * - Attempts to get the user data from the session storage.
     * - If the user data is not found in the session storage, it attempts to get the user data directly from the API.
     * - If the user data is found, it sets the user data to the vuex store.
     * @author Sebastian Inman <sinman@nicindustries.com>
     */
    async getUser() {
      // Prevent multiple requests for user data.
      if (this.loadingUserData) return;

      // Set the loading flag.
      this.loadingUserData = true;

      /**
       * Attempt to get the user data from the session storage.
       * @returns {object | undefined} The user data.
       */
      async function getUserFromSessionStorage() {

        try {
          // Get the user data from the session storage.
          const user = JSON.parse(sessionStorage.getItem("user"));

          // Return the user data.
          return user;

        } catch (error) {
          // The user data could not be retrieved from the session storage.
          console.warn('Unable to get user data from session storage.', error);

          // Attempt to get the user data directly from the API instead.
          return await getUserFromAPI();

        }

      }

      /**
       * Attempt to get the user data directly from the API.
       * @returns {object | undefined} The user data.
       */
      async function getUserFromAPI() {

        try {

          // Get the user data directly from the API.
          const { data: user } = await axios.get("/api/proxify/me");

          // Save the user data to the session storage.
          sessionStorage.setItem("user", JSON.stringify(user));

          // Return the user data.
          return user;

        } catch (error) {
          // The user data could not be retrieved from the session storage.
          console.error('Unable to fetch user data from the API.', error);

          // Unable to get the user data, return undefined.
          return undefined;

        }

      }

      // Attempt to get the user data.
      const user = sessionStorage.getItem("user")
        ? await getUserFromSessionStorage()
        : await getUserFromAPI();

      this.setMe(user);
      this.setIsLoggedIn(!!user);
      this.fixLegacyLinks(user);

      if (user) {
        // Set the "logged_in" cookie.
        Cookie.set("logged_in", 1, { expires: 1440, path: "/" });
      } else {
        // Remove the "logged_in" cookie.
        Cookie.remove("logged_in", { path: "/" });
      }

      // Reset the loading flag.
      this.loadingUserData = false;

      let ssoParam = new URLSearchParams(window.location.search);

      if (ssoParam.has("sign-in")) {
        window.history.pushState({}, "", window.location.origin);
      }

    },

    /**
     * @function getCart
     * @desc fetches cart data and saves to vuex.
     * @todo implement local browser storage to prevent over fetching on every page load.
     * @returns {object}
     */
    async getCart() {
      const getCartEndpoint = `/api/proxify/domains/prismatic/shop/cart`;
      this.loadingCartData = true;

      try {
        const { data: response } = await axios.get(getCartEndpoint);

        if (Object.keys(response).length) {
          this.setLocalCart(response);
        }
      } catch (err) {
        //prevent error notification when user auth is removed
        if (err.response.status === 403) {
          return;
        } else {
          console.error(err);
          if (err) {
            this.$root.$refs.notifications.add({
              delay: false,
              type: "error",
              icon: "exclamation-triangle",
              title: "Unable to load user cart",
              message: `Please reload the page, or contact us for help.`,
            });
          }
        }
      } finally {
        this.loadingCartData = false;
      }
    },

    fixLegacyLinks(profileData) {

      if (!profileData) {
        return;
      }

      let websiteString = profileData.website;

      if (websiteString != null) {
        if (websiteString.substring(0, 7) === "http://") {
          profileData.website = websiteString.substring(7);
        } else if (websiteString.substring(0, 8) === "https://") {
          profileData.website = websiteString.substring(8);
        }
      }

      let facebookString = profileData.facebook_url;

      if (facebookString != null) {
        if (facebookString.substring(0, 7) === "http://") {
          profileData.facebook_url = facebookString.substring(7);
        } else if (facebookString.substring(0, 8) === "https://") {
          profileData.facebook_url = facebookString.substring(8);
        }
      }

      let instagramString = profileData.instagram_url;

      if (instagramString != null) {
        if (instagramString.substring(0, 7) === "http://") {
          profileData.instagram_url = instagramString.substring(7);
        } else if (instagramString.substring(0, 8) === "https://") {
          profileData.instagram_url = instagramString.substring(8);
        }
      }
    },
  },
};
