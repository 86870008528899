<template>
  <div id="footer-content" class="container-fluid">
    <div class="row">
      <div class="footer-divider col-md-12">
        <div class="divider-logo">
          <prisLogo />
        </div>
        <hr />
      </div>
      <div class="col-xs-12 footlinks">
        <div class="row">
          <div class="col-sm-6 col-lg-2 desktop-only">
            <h3>Customer Service</h3>
            <ul>
              <li v-if="$root.isLoggedIn">
                <a href="/order-history">Check order status</a>
              </li>
              <li><a href="/terms-and-policies?to=returns">Returns</a></li>
              <li>
                <a href="/terms-and-policies?to=shipping"
                  >Shipping & Delivery</a
                >
              </li>
              <li><a href="/contact-us">Contact Us</a></li>
              <li><a href="/terms-and-policies">Terms & Policies</a></li>
            </ul>
          </div>
          <div class="col-sm-6 col-lg-2 desktop-only">
            <h3>About Us</h3>
            <ul>
              <li><a href="/about-us">Who we are</a></li>
              <li><a href="/career">Careers</a></li>
              <li><a href="/testimonials">Customer Testimonials</a></li>
              <li><a href="/news">Announcement Hub</a></li>
            </ul>
          </div>
          <div class="col-sm-6 col-lg-2 desktop-only">
            <h3>Resources</h3>
            <ul>
              <li>
                <a href="/shop/powder-coating-colors"
                  >Shop Powder Coating Colors</a
                >
              </li>
              <li><a href="shop/color-match">Color Match</a></li>
              <li><a href="/gallery">Applicator Projects</a></li>
              <li>
                <a href="/find-applicator">Find an Applicator Near You</a>
              </li>
              <li><a href="/knowledge-base">Knowledge Base</a></li>
              <li><a href="/documents">Documents & Downloads</a></li>
              <li><a href="/videos">Videos</a></li>
            </ul>
          </div>
          <div class="col-sm-6 col-lg-2 desktop-only">
            <h3>We're Worldwide</h3>
            <ul>
              <li><a href="/location">See Locations</a></li>
            </ul>
            <h3>Innovations of NIC</h3>
            <ul>
              <li>
                <a
                  rel="noopener"
                  href="https://www.cerakote.com/?utm_source=prismatic&utm_medium=referral&utm_campaign=&utm_content=footer-link"
                  target="_blank"
                  >Cerakote<sup>®</sup></a
                >
              </li>
              <li>
                <a
                  rel="noopener"
                  href="http://www.nicindustries.com/"
                  class="logo-nic"
                  target="_blank"
                  >NIC Industries Inc.<sup>®</sup></a
                >
              </li>
              <li>
                <a
                  rel="noopener"
                  href="https://cerakoteceramics.com/?utm_source=prismatic&utm_medium=referral&utm_campaign=&utm_content=footer-link"
                  target="_blank"
                  >Performance Materials</a
                >
              </li>
            </ul>
            <h3>
              We're Always Looking To Improve<br /><small
                >Tell us what you love or what we need to fix.</small
              >
            </h3>
            <ul>
              <li>
                <a
                  href="mailto:info@prismaticpowders.com"
                  class="btn btn-alt-outline"
                  >Give Feedback</a
                >
              </li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3 col-lg-offset-1">
            <h5>
              Need Help? Call us at:
              <a href="tel:1-866-774-7628">1-866-774-7628</a>
            </h5>
            <h3>
              {{ $root.domainName }} Newsletter<br /><small
                >Subscribe to receive exclusive promos and discounts!</small
              >
            </h3>
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup-footer">
              <form
                action="https://nicindustries.us1.list-manage.com/subscribe/post?u=99fe9d627854feb5c257b8347&amp;id=9ee76ca792"
                method="post"
                id="mc-embedded-subscribe-form-footer"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                novalidate
              >
                <div id="mc_embed_signup_scroll-footer">
                  <div class="mc-field-group form-group">
                    <input
                      type="email"
                      placeholder="* Email Address"
                      name="EMAIL"
                      class="required email form-control"
                      id="mce-EMAIL-footer"
                    />
                  </div>
                  <div id="mce-responses-footer" class="clear">
                    <div
                      class="response"
                      id="mce-error-response-footer"
                      style="display: none"
                    ></div>
                    <div
                      class="response"
                      id="mce-success-response-footer"
                      style="display: none"
                    ></div>
                  </div>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div
                    style="position: absolute; left: -5000px"
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_99fe9d627854feb5c257b8347_9ee76ca792"
                      tabindex="-1"
                      value=""
                    />
                  </div>
                  <div class="clear form-group">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe-footer"
                      class="button btn btn-blue-subscribe"
                      style="width: 100%"
                    />
                  </div>
                  <div class="indicates-required">
                    <span class="asterisk">* indicates required</span>
                  </div>
                </div>
              </form>
            </div>

            <div class="sm-links">
              <h6>Got Social? Follow Us</h6>
              <div>
                <a
                  rel="noopener"
                  href="https://www.facebook.com/PrismaticPowders/"
                  ><i
                    class="fa fa-facebook fa-lg"
                    aria-hidden="true"
                    style="margin: 4px"
                  ></i
                ></a>
                <a
                  rel="noopener"
                  href="https://www.youtube.com/user/PrismaticPowders"
                  ><i
                    class="fa fa-youtube fa-lg"
                    aria-hidden="true"
                    style="margin: 4px"
                  ></i
                ></a>
                <a rel="noopener" href="https://twitter.com/prismaticpowder"
                  ><i
                    class="fa fa-twitter fa-lg"
                    aria-hidden="true"
                    style="margin: 4px"
                  ></i
                ></a>
                <a
                  rel="noopener"
                  href="https://www.pinterest.com/prismaticpowdrs/prismatic-powders-projects/"
                  ><i
                    class="fa fa-pinterest fa-lg"
                    aria-hidden="true"
                    style="margin: 4px"
                  ></i
                ></a>
                <a
                  rel="noopener"
                  href="https://www.instagram.com/prismaticpowders/?hl=en"
                  ><i
                    class="fa fa-instagram fa-lg"
                    aria-hidden="true"
                    style="margin: 4px"
                  ></i
                ></a>
                <a
                  rel="noopener"
                  href="mailto:info@prismaticpowders.com?subject=Prismatic Powders&body=Check out this website: https://www.prismaticpowders.com/"
                  ><i
                    class="fa fa-envelope-o fa-lg"
                    aria-hidden="true"
                    style="margin: 4px"
                  ></i
                ></a>
              </div>
            </div>
            <!--End mc_embed_signup-->
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3 mobile-only">
            <h3 class="tablet-only">International</h3>
            <ul class="tablet-only">
              <li><a href="/location" class="globe">Locations</a></li>
            </ul>
            <br class="tablet-only" />
            <h3 class="tablet-only">Our other divisions</h3>
            <ul class="tablet-only">
              <li>
                <a
                  rel="noopener"
                  href="https://www.cerakote.com/?utm_source=prismatic&utm_medium=referral&utm_campaign=&utm_content=footer-link"
                  target="_blank"
                  >Cerakote<sup>®</sup></a
                >
              </li>
              <li>
                <a
                  rel="noopener"
                  href="https://cerakoteceramics.com/?utm_source=prismatic&utm_medium=referral&utm_content=footer-link"
                  target="_blank"
                  >Performance Materials</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /footlinks -->
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-xs-12 copyright">
        <div class="copyright-details">
          <span
            >&copy; {{ new Date().getFullYear() }}
            <span v-html="$root.domainTrademark"></span>. All Rights Reserved. </span
          ><a href="/terms-and-policies">Terms of Use</a>
        </div>
      </div>
      <!-- /copyright -->
    </div>
    <!-- /row -->
  </div>
  <!-- /container-fluid -->
</template>

<script>
import prisLogo from "../icons/prisLogo.vue";

export default {
  name: "footer-content",

  components: {
    prisLogo,
  },
};
</script>

<style scoped lang="scss">
#footer-content {
  .footer-divider {
    height: 50px;
    margin-top: 45px;

    .divider-logo {
      position: absolute;
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
      background-color: #393939;
    }

    hr {
      margin: revert;
      margin-top: 1%;
      border-color: #555;
    }
  }
}
</style>
