<template>
  <div id="site-search">
    <form class="navbar-form navbar-left" role="search" :action="`/search/${URLSlugify(searchText)}`">
      <div class="form-group input-group">
        <input type="text" class="form-control" placeholder="Search PRISMATIC POWDERS®" v-model="searchText">
        <span class="input-group-btn">
          <button type="submit" class="btn btn-blue">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import url from '../../../mixins/url';

export default {
  name: 'site-search',

  mixins: [
    url,
  ],

  data() {
    return {
      searchText: '',
    }
  }
}
</script>

<style scoped lang="scss">
#site-search {
  input {
    height: 43px;
    width: 350px;
  }
}
</style>