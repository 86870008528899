<template>
  <modal id="informationModal">
    <template slot="header" v-if="computedTitle">
      <button type="button" class="close close-popup" data-dismiss="modal" aria-label="Close"><span
        aria-hidden="true" @click.prevent="cancelAction">×</span></button>
      <h2 class="modal-title">{{ computedTitle }}</h2>
    </template>

    <p v-html="computedMessage"></p>

    <template slot="footer" v-if="okCancel">
        <div style="display:inline-block;float:left;">
          <div id="captcha2" v-if="okCancelInput"></div>
        </div>
        <div class="modalEmail" style="display:inline-block;">
          <input type="email" v-if="okCancelInput" v-validate="'required|email'" name="emailModal" id="emailModal" placeholder="Email Address" v-model="email" class="form-control" />
        </div>
      <div style="display:inline-block;">
      <button type="button" class="btn btn-black" @click.prevent="okAction" :disabled="loading">
        {{ okCancelText[0] }}
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
      </button>
      <button type="button" class="btn btn-black" @click.prevent="cancelAction" :disabled="loading">
        {{ okCancelText[1] }}
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
      </button>
      </div>
    </template>
    <template slot="footer" v-else>
      <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-black" @click.prevent="cancelAction">Close</button>
    </template>
  </modal>
</template>

<script>
  export default {
    props: {
      title: String,
      message: String,
      loading: Boolean,
      okCancel: Boolean,
      okCancelInput: Boolean,
      okCancelInputHold:{default: false, type: Boolean},
      okCancelValue: String,
      okCancelCaptchaVal: String,
      okCancelText: {
        type: Array,
        default: () => ['Ok', 'Cancel']
      },
    },

    data() {
      return {
        email:"",
        requires_captcha: true,
        captcha_response: false,
      };
    },

    computed: {

      computedTitle() {
        return this.title || this.$root.lockedTitle || undefined;
      },

      computedMessage() {
        return this.message || this.$root.lockedMessage || undefined;
      },

      okCancelInputFlag() {
        if ( this.okCancelInput !== undefined || this.okCancelInput) {
          return this.okCancelInput;
        }

        return false;
      },

      okCancelInputWaitFlag() {
        if (this.okCancelInputHold !== undefined || this.okCancelInputHold )  {
          return this.okCancelInputHold;
        }

        return false;
      },
    },

    updated() {
      if (this.okCancelInputFlag) {
        let vm = this;

        const captchaParams = {
          sitekey: process.env.MIX_RECAPTCHA_SITEKEY,
          callback: (response) =>
            (vm.captcha_response = response),
          expiredCallback: () => (vm.captcha_response = null),
          errorCallback: (error) => {
            vm.captcha_response = null;
            console.error(error);
          },}
        if (document.querySelector('#captcha2')?.firstChild === undefined || document.querySelector('#captcha2')?.firstChild === null) {
          window.grecaptcha.render('captcha2', captchaParams);
        }
      }
    },


    methods: {
      okAction() {
        this.$emit('ok-action');
        this.$emit('email-notify', this.email);
        this.$emit('captcha-response', this.captcha_response);
        this.$emit('ok-cancel-input-hold');
        this.$root.$emit('ok-action');
        this.$root.$emit('email-notify', this.email);
        this.$root.$emit('captcha-response', this.captcha_response);
        this.$root.$emit('ok-cancel-input-hold');
        if (!this.okCancelInputWaitFlag) {
          $('#informationModal').modal('hide');
        }
      },

      cancelAction() {
        this.$emit('cancel-action');
        this.$root.$emit('cancel-action');
        $('#informationModal').modal('hide');
      },

    }
  }
</script>
