/**
  * @file data-layer.js
  * @summary handles the JavaScript dataLayer bindings.
  * @alias dataLayer
 */

//TODO: there are other areas in the project that can be refactored and brought in here. Search for "dataLayer".

export default {
  methods: {
    // !IMPORTANT - 👇 Google no longer supports the legacy GA.
    setGADataLayer(params) {
      const data = { ...params };

      window.dataLayer.push({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            products: [
              {
                name: data.item.name,
                id: data.item.sku,
                price: data.total,
                brand: "prismatic",
                category: data.type,
                quantity: parseInt(data.qty),
              },
            ],
          },
        },
      });
    },

    // for GA4
    setGA4DataLayer(params) {
      const data = { ...params };

      dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "USD",
          value: parseFloat(data.cartTotal),
          items: [
            {
              item_id: data.item.sku,
              item_name: data.item.name,
              coupon: undefined,
              currency: "USD",
              discount: undefined,
              index: undefined,
              item_brand: "Prismatic",
              item_category: data.type,
              item_list_id: undefined,
              item_list_name: undefined,
              item_variant: undefined,
              location_id: data.locationId,
              price: data.total,
              quantity: parseInt(data.qty),
            },
          ],
        },
      });
    },
  },
};
