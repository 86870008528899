<template>
  <section id="featured-industries" class="row"></section>
</template>

<script>
export default {
  name: 'featured-industries',
}
</script>

<style scoped lang="scss">
#featured-industries {
  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}
</style>