<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <div v-if="response.type" class="alert" :class="`alert-${response.type}`">
          {{ response.message }}
        </div>
        <h2 class="resetForm">Change Password</h2>
      </div><!-- /form-group -->
      <div class="form-group resetForm" :class="{ 'has-error': errors.has('password') }">
        <label for="password">Current Password</label>
        <input type="password" v-validate="'required'" name="current password"
          v-model="resetPasswordForm.current_password" id="current_password" class="form-control">
        <span v-show="response.type === 'danger'" class="text-danger"><i class="fa fa-exclamation-circle"></i> {{
          response.message }}</span>
      </div><!-- /form-group -->
      <div class="form-group resetForm" :class="{ 'has-error': errors.has('password') }">
        <label for="password">New Password</label>
        <input type="password" v-validate="'required'" name="password" v-model="resetPasswordForm.new_password"
          @input="password_check" id="new_password" class="form-control" autocomplete="new-password">
        <span v-show="errors.has('password')" class="text-danger"><i class="fa fa-exclamation-circle"></i> {{
          errors.first('password') }}</span>
      </div><!-- /form-group -->
      <div class="form-group resetForm" :class="{ 'has-error': errors.has('confirmPassword') }">
        <label for="password">Confirm New Password</label>
        <input type="password" v-validate="'required'" name="password confirmation" v-model="confirmPassword"
          @input="password_check" id="confirm_password" class="form-control" autocomplete="new-password">
        <span v-show="errors.has('Password confirmation')" class="text-danger"><i class="fa fa-exclamation-circle"></i> {{
          errors.first('Password confirmation') }}</span>
        <span v-show="pwErrors" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errorMsg }}
        </span>
      </div><!-- /form-group -->

      <PasswordValidation v-if="!hasSentPWReset" :newPassword="resetPasswordForm.new_password"
        :confirmPassword="confirmPassword" @validate-password="setIsValidPassword" @password-errors="setPWErrors"
        @error-msg="setErrorMsg" ref="pwValidation" />

      <div class="form-group resetForm text-center">
        <button type="submit" class="btn btn-block btn-orange" :disabled="!isValidPassword">
          Submit <i class="fa"
            v-bind:class="{ 'fa-spinner fa-spin': resetPasswordForm.processing, 'fa-arrow-right': !resetPasswordForm.processing }"
            aria-hidden="true"></i>
        </button>
      </div><!-- /form-group -->
    </form>
  </div>
</template>

<script>
import PasswordValidation from '../authentication/PasswordValidation.vue';
import signOut from '../../mixins/sign_out';

const url = `/api/proxify/me/update-password`;

export default {
  name: 'reset-password',

  components: {
    PasswordValidation
  },

  mixins: [
    signOut
  ],

  data() {
    return {
      resetPasswordForm: new Form({
        current_password: '',
        new_password: '',
      }),
      response: {},
      confirmPassword: '',
      isValidPassword: false,
      pwErrors: false,
      errorMsg: ''
    };
  },

  computed: {
    hasSentPWReset() {
      if (this.confirmPassword === 'Your password has been successfully reset.') {
        return true;
      }

      return false;
    }
  },

  methods: {
    //used password_check, setIsValidPassword, setPWErrors, setErrorMsg for the PasswordValidation component
    password_check() {
      this.$refs.pwValidation.password_check();
    },
    setIsValidPassword(isValid) {
      this.isValidPassword = isValid;
    },
    setPWErrors(errorValue) {
      this.pwErrors = errorValue;
    },
    setErrorMsg(msgValue) {
      this.errorMsg = msgValue;
    },

    submitForm() {
      this.$validator
        .validateAll()
        .then((success) => {
          if (!success) {
            return;
          }

          if (this.resetPasswordForm.new_password !== this.confirmPassword) {
            this.response.type = 'danger';
            this.response.message = 'Confirmation Password Needs to Match';
            return;
          }

          this.resetPasswordForm
            .post(url)
            .then((response) => {

              if (response.success) {
                this.response = {
                  type: 'success',
                  message: 'Your password has been successfully reset. You will be automatically signed out.',
                };

                setTimeout(() => {
                  this.signOut(true);
                }, 500)
              }
            })
            .catch((response) => {
              this.response = {
                type: 'danger',
                message: 'Your current password was incorrect.',
              };
            });
        })
        .catch(() => {
          // shhh...
        });
    },
  },
};
</script>

<style scoped>
.form-group>h2 {
  width: 38%;
}
</style>
