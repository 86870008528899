<template>
  <section id="featured-videos" class="row text-center">
    <h1 class="title-underline">Featured Videos</h1>
    <div>
      <div class="video-group col-md-10 col-md-offset-1" v-if="featured_videos.length > 0">
        <div class="featured-video col-sm-12 col-md-4" v-for="(video, index) in featured_videos" :key="index">
          <div class="thumbnail">
            <iframe :style="'border: none; width: 100%; border-radius: 6px;'" height="250"
              :src="`https://www.youtube.com/embed/${video.youtube_embed_id}`" allowfullscreen="allowfullscreen"></iframe>
            <div class="caption">
              <h3>{{ video.title }}</h3>
              <p v-html="video.description.substring(0, 100) + '...'"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="video-group col-md-10 col-md-offset-1" v-else>
        <div class="col-sm-12">
          <h3 class="text-center"><i class="fa fa-frown-o" aria-hidden="true"></i>&nbsp;Sorry, no featured videos</h3>
        </div>
      </div>
      <div class="video-cta col-md-12">
        <h3>
          <a href="/videos" class="btn btn-link">View All Videos <i class="fa fa-long-arrow-right"
              aria-hidden="true"></i></a>
        </h3>
      </div>
    </div>

  </section>
</template>

<script>
import images from '../../../../../mixins/images';

export default {
  name: 'featured-videos',

  mixins: [
    images
  ],

  data() {
    return {
      featured_videos: window._PR?.featured_videos || [],
    }
  }
}
</script>

<style scoped lang="scss">
#featured-videos {
  margin-bottom: 3%;

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.0rem;
  }

  .video-group {
    display: flex;

    .featured-video {
      margin-top: 20px;
      flex: 1;

      .thumbnail {
        box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
        margin: 0 10px;
        padding: 1%;
        border: none;
        border-radius: 6px;
        height: 100%;
        display: grid;

        .caption {
          p {
            margin-top: 5%;
          }
        }
      }
    }
  }

  .video-cta {
    margin-top: 20px;

    a {
      font-size: 2rem;
      letter-spacing: 1rem;
      text-decoration: none;

      .fa-long-arrow-right {
        transition: transform .3s ease-in-out;
      }
    }

    a:hover {
      .fa-long-arrow-right {
        transform: translate(5px, 0);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .video-group {
      padding: 0;
      display: inherit;

      .featured-video {
        padding: 0;

        .thumbnail {
          margin: 0;
        }
      }
    }

    .video-cta {
      a {
        letter-spacing: inherit;
      }
    }
  }
}
</style>
