export default {
  data() {
    return {
      currentDate: new Date().toISOString().slice(0,10) || '',
      createdDate: localStorage.getItem('_countries_created_at') || '',
      countries: [],
    };
  },

  methods: {

    /**
    * @loadCountryList
    * @description fetch the list of countries from the API
    */

    async loadCountryList() {
      const countriesUrl = '/api/proxify/domains/prismatic/shop/countries?fields=code,long_name,postal_required,region_required';
      let countries = [];

      await axios.get(countriesUrl)
        .then(({ data: response }) => {
          this.setCountryCreationDate();
          localStorage.setItem('_countries_list', JSON.stringify(response));
          countries = response;
        }).catch(error => {
          console.log(error);
      });

      return countries;
    },

    /**
    * @setCountryCreationDate
    * @description sets the country creation date
    */

    setCountryCreationDate() {
      localStorage.setItem('_countries_created_at', JSON.stringify(this.currentDate));
    },

    /**
    * @checkCountryDates
    * @description checks if the country list was created today
    */

    checkCountryDates() {
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

      if (Date.parse(this.currentDate) - Date.parse(this.createdDate) > thirtyDaysInMilliseconds) {
        localStorage.removeItem('_countries_created_at');
        localStorage.removeItem('_countries_list');
      }
    },

    /**
    * @getCountries
    * @description returns the response from the local storage if it exists, otherwise fetch it from the API
    */

    getCountries() {
      let vm = this;

      vm.checkCountryDates();

      if (localStorage.getItem('_countries_list') === null) {
        vm.loadCountryList().then(function(response) {
          vm.countries = response;
        });
      } else {
        vm.countries = JSON.parse(localStorage.getItem('_countries_list'));
      }
    },
  },
}