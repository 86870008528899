<template>
  <section class="row text-center">
    <div id="featured-quote-carousel" class="carousel slide" data-ride="carousel">
      <h1 class="title-underline">Testimonials</h1>
      <!-- Wrapper for slides -->
      <div class="carousel-inner" role="listbox">
        <div class="item active">
          <p><i class="fa fa-quote-right fa-2x" aria-hidden="true"></i>You guys pulled through for me again! One little
            mistake here and we are in a jam. One phone call to {{ $root.domainName }} and it's fixed. You guys are the
            most awesome customer service and I can't tell you how much I appreciate all of you! - Carol</p>
        </div>
        <div class="item">
          <p><i class="fa fa-quote-right fa-2x" aria-hidden="true"></i>We know in order to produce great powder coating
            work one must use the best products available. {{ $root.domainName }} Powders has hands down the best color
            selection and the highest quality powders available. I have posted many many times that Super Chrome is by far
            the best chrome powder coating on the market. - James</p>
        </div>
        <div class="item">
          <p><i class="fa fa-quote-right fa-2x" aria-hidden="true"></i>I use your powder on all of the choppers and drag
            cars we do. I really enjoy your products.The customer is always blown away by the end results. Thanks for
            pushing the powder limits, it has busted our companies custom side of the business. Besides the fact that I
            enjoy shooting your products. Im using oxblood hues for the next chopper. - Daniel</p>
        </div>
        <div class="col-sm-12 all-testimonials">
          <a href="/testimonials" class="btn btn-black">View All Testimonials</a>
        </div>
      </div>
      <!-- Controls -->
      <a class="left carousel-control" href="#featured-quote-carousel" role="button" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#featured-quote-carousel" role="button" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'featured-quotes',
}
</script>

<style scoped lang="scss">
#featured-quote-carousel {
  background: rgb(2, 0, 36);
  background: linear-gradient(to right, #ff6C00, #f44336);
  color: #fafafa;
  min-height: 300px;
  margin-bottom: 3%;
  padding: 5% 0;

  .title-underline {
    margin-bottom: 1%;
    color: #fafafa;
  }

  .all-testimonials {
    padding-bottom: 2%;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  .carousel-inner {
    overflow: inherit;

    .item {
      height: 155px;

      i {
        margin-right: 1%;
        padding-right: 1%;
        border-right: 6px solid #fafafa;
      }

      p {
        display: flex;
        margin: 0 auto;
        width: 55%;
        font-size: 1.7rem;
        transform: translateY(35%);
        top: 35%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #featured-quote-carousel {
    min-height: 400px;
    margin-top: 5%;
    margin-bottom: 5%;

    .carousel-inner {
      .item {
        padding: 0 5%;
        height: 350px;

        p {
          width: 90%;
          transform: translateY(10%);
        }
      }
    }
  }
}
</style>