/**
 * @author Sebastian Inman
 * @created April 17, 2020
 * @modified April 17, 2020
 * This mixin provides multiple methods for handling
 * and modifying image sources before sending them
 * to the front-end for rendering.
 */

export default {
  methods: {
    /**
     * @method imageFallback
     * @author Sebastian Inman
     * @since January 24, 2020
     * @description Replaces the source of an image with a fallback if the original source fails to load.
     * @param {Object} event The "onerror" event fired by the image element.
     * @param {String} fallbackImage The full path to the fallback image.
     * @example <img src="broken-image.png" @error="imageFallback($event, '/img/default-product-img.png')"/>
     */

    imageFallback(event, fallbackImage = undefined) {
      // This method only works when called from the "onerror" callback
      // on image elements. The onerror event must be passed through to
      // this method in order for it to work properly, so we check for it.

      if (typeof event === "object" || event.type === "error") {
        // If the image has a "fallback / data-fallback" attribute use that
        // value instead of the fallback parameter assigned by the method.

        fallbackImage = event.target.dataset.fallback || fallbackImage;

        // Only attempt to apply the fallback image if it exists and is not empty.
        // If no fallback is supplied, either as an argument when calling the method,
        // or as a data attribute, nothing will happen and the image will break just like normal.

        if (typeof fallbackImage == "string" && fallbackImage !== "") {
          // Set the fallback image path as the source of the image DOM element.

          event.target.src = fallbackImage;
        }
      }
    },

    /**
     *
     * @param {string} imageURL
     * @param {number} size
     * @returns {string}
     */

    ImageResize(imageURL, size) {
      if (imageURL.endsWith("?") || imageURL.endsWith("&"))
        return `${imageURL}size=${size}`;
      if (imageURL.includes("?") || imageURL.includes("&"))
        return `${imageURL}&size=${size}`;
      if (imageURL.includes("default-product-img.png?size=100")) return imageURL;

      return `${imageURL}?size=${size}`;
    }
  }
};
