// This mixin attach behavior to various DOM elements using third party libraries like gmaps etc.
import signOut from "./sign_out";

export default {
  mixins: [signOut],

  data() {
    return {
      domainName: "Prismatic Powders",
      domainTrademark: `PRISMATIC POWDERS<sup>®</sup>`,
      loading: false,
      showFooter: true,
      notifications: window._PR?.notifications || [],
      onPageMessage: [],
      onPageShopImg: [],
      onPageHomeImg: [],
      onPageGalleryImg: [],
      onPageApplicatorImg: [],
      onPageCartImg: [],
      showCookieDisabled: false,
      footerNewsletterEmail: "",
      showShopMenuMobile: false,
      showProductTestingMenuMobile: false,
      showIndustriesMenuMobile: false,
      showVsMenuMobile: false,
      showIndustriesMenuMobile: false,
      showResourcesMenuMobile: false,
      showMyAccountMenuMobile: false,
      desktopHeaderSize: 0,
      mobileHeaderSize: 0,
      // setting the domain name for global use
      domain: "prismatic",
      build: process.env.NODE_ENV,
      isMobile: false,
    };
  },

  computed: {
    hasSalesPromo() {
      if (this.$root.notifications.length > 0) {
        return true;
      }

      return false;
    },

    thisCountry() {
      if (window.location.pathname === "/canada") {
        return "Canada";
      } else if (
        window.location.pathname === "/deutschland-pulverbeschichtung"
      ) {
        return "Germany";
      } else if (window.location.pathname === "/australia") {
        return "Australia";
      } else if (window.location.pathname === "/uk") {
        return "UK";
      } else if (window.location.pathname === "/colombia") {
        return "Colombia";
      } else if (window.location.pathname === "/europe") {
        return "Europe";
      } else if (window.location.pathname === "/asia") {
        return "Asia";
      } else if (window.location.pathname === "/middle-east") {
        return "Middle East";
      } else if (window.location.pathname === "/hong-kong") {
        return "Hong Kong";
      } else if (window.location.pathname === "/south-korea") {
        return "South Korea";
      }
      return "USA";
    },

    pageName() {
      let pagePath = window.location.pathname;
      let output = "";

      switch (pagePath) {
        case "/career":
          output = "Careers";
          break;
        case "/about-us":
          output = "About";
          break;
        case "/documents":
          output = "Documents";
          break;
        case "/todays-deals":
          output = "Sales";
          break;
        case "/brands":
          output = "Brands";
          break;
        case "/gallery":
          output = "Gallery";
          break;
        case "/shop/powder-coating-creations":
          output = "Creations";
          break;
        case "/shop/color-match":
          output = "Color Match";
          break;
        case "/my-page":
          output = "Profile";
          break;
        case "/cart":
          output = "Cart";
          break;
        case "/":
          output = "Home";
          break;
      }

      return output;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth <= 640) {
        this.isMobile = true;
      }

      window.addEventListener('resize', this.onResize);
    })

    this.getHeaderSizes();

    let cookieEnabled = navigator.cookieEnabled;
    if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
    }

    if (!cookieEnabled) {
      this.showCookieDisabled = true;
    }
    /**
     * @todo Place this somewhere else, probably in a global event and push that to app_events.js
     */
    this.$on("change-modal", function (view) {
      this.currentModalView = view;

      if (view === "signin") {
        this.carriedOverMessage = {};
      }
    });

    // this.getNotifications();

    window.addEventListener("resize", this.getHeaderSizes);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 640;
    },

    isCurrentShopPage(page) {
      const currentPathArray = window.location.pathname.split("/");
      const currentShopPage = currentPathArray[currentPathArray.length - 1];

      return page === currentShopPage;
    },

    getHeaderSizes() {
      setTimeout(() => {
        this.desktopHeaderSize = `${$("#siteNavbar").outerHeight()}px`;
        document.documentElement.style.setProperty(
          "--desktop-header-size",
          this.desktopHeaderSize
        );

        this.mobileHeaderSize = `${$(".mobile-head-wrapper").outerHeight()}px`;
        document.documentElement.style.setProperty(
          "--mobile-header-size",
          this.mobileHeaderSize
        );
      }, 500);
    },

    openMobileMenu(section) {
      if (!section) return;

      if (section === "shop") {
        this.showShopMenuMobile = true;
      } else if (section === "resources") {
        this.showResourcesMenuMobile = true;
      } else if (section === 'industries') {
        this.showIndustriesMenuMobile = true;
      } else if (section === "your account") {
        this.showMyAccountMenuMobile = true;
      }

      $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
      $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
    },

    closeMobileMenu(section) {
      if (!section) return;

      if (section === "shop") {
        this.showShopMenuMobile = false;
      } else if (section === "resources") {
        this.showResourcesMenuMobile = false;
      } else if (section === 'industries') {
        this.showIndustriesMenuMobile = false;
      } else if (section === "your account") {
        this.showMyAccountMenuMobile = false;
      }

      $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
      $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
    },

    addToNewsletter(email) {
      const emailRegex = /\S+@\S+\.\S+/g;
      const hasValidEmail = email.length && emailRegex.test(email);

      if (hasValidEmail) {
        axios
          .post(`/newsletter-subscribe-email/${email}`, {})
          .then((response) => {
            // do nothing
            alert(
              "You have been successfully added to the PRISMATIC POWDERS® Newsletter."
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("Please enter a valid email address.");
      }
    },

    getNotifications() {

      for (let notification of this.notifications) {
        if (notification.notification_type === "notification_bar") {
          this.notifications.push(notification);
        } else if (
          notification.notification_type === "on_page" &&
          notification.on_shop &&
          notification.desktop_image_url
        ) {
          if (!this.onPageShopImg.length) {
            this.onPageShopImg.push(notification);
          }
        } else if (
          notification.notification_type === "on_page" &&
          notification.on_home &&
          notification.desktop_image_url
        ) {
          if (!this.onPageHomeImg.length) {
            this.onPageHomeImg.push(notification);
          }
        } else if (
          notification.notification_type === "on_page" &&
          notification.on_gallery &&
          notification.desktop_image_url
        ) {
          if (!this.onPageGalleryImg.length) {
            this.onPageGalleryImg.push(notification);
          }
        } else if (
          notification.notification_type === "on_page" &&
          notification.on_applicator &&
          notification.desktop_image_url
        ) {
          if (!this.onPageApplicatorImg.length) {
            this.onPageApplicatorImg.push(notification);
          }
        } else if (
          notification.notification_type === "on_page" &&
          notification.on_cart &&
          notification.desktop_image_url
        ) {
          if (!this.onPageCartImg.length) {
            this.onPageCartImg.push(notification);
          }
        } else if (notification.notification_type === "on_page") {
          this.onPageMessage.push(notification);
        }
      }

      this.getHeaderSizes();

    },

    showCartBanner() {
      $(".add-to-cart")
        .css("visibility", "visible")
        .addClass("show-cart-popup");
    },

    hideCartBanner() {
      $(".add-to-cart")
        .css("visibility", "hidden")
        .removeClass("show-cart-popup");
    },

    slug(str) {
      if (str) {
        return slugify(str).toLowerCase();
      }

      return "";
    },

    async deleteWishlistItem(productId) {
      const url = `/api/proxify/me/wishlist/${productId}`;

      try {
        let response = await axios.delete(url, {
          data: {},
        });
        if (response.data.success) {
          this.$refs[`wishlistItem${productId}`].remove();
        }
      } catch (error) {
        alert("Could not remove from wishlist");
      }
    },

    initiateSignOut() {
      this.signOut(true);
    },
  },
};
