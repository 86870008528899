<template>
    <modal id="notificationPopup">
      <button type="button" class="close close-popup" data-dismiss="modal" aria-label="Close"
        style="top: -15px; left: 15px;">
        <span aria-hidden="true">×</span>
      </button>
      <div v-if="popup && popup.desktop_image_url">
        <div v-if="$mq === 'desktop' || $mq === '991'">
          <a v-if="popup.target_url" :href="popup.target_url"><img :src="popup.desktop_image_url" style="height: auto;display: block;width: 100%;" /></a>
          <img :src="popup.desktop_image_url" v-else style="height: auto;display: block;width: 100%;" />
        </div>
        <div v-if="$mq === '767'">
          <a v-if="popup.target_url" :href="popup.target_url"><img :src="popup.tablet_image_url" style="height: auto;display: block;width: 100%;" /></a>
          <img :src="popup.tablet_image_url" v-else style="height: auto;display: block;width: 100%;" />
        </div>
        <div v-if="$mq === '600' || $mq === '479'">
          <a v-if="popup.target_url" :href="popup.target_url"><img :src="popup.mobile_image_url" style="height: auto;display: block;width: 100%;" /></a>
          <img :src="popup.mobile_image_url" v-else style="height: auto;display: block;width: 100%;" />
        </div>
      </div>
      <div v-else-if="popup" style="padding: 20px;">
        <a v-if="popup.target_url" :href="popup.target_url">{{ popup.message }}</a>
        <span v-else>{{ popup.message }}</span>
      </div>
    </modal>
</template>

<script>

    export default {
      data() {
        return {
        }
      },

      computed: {
        popup() {
          return this.$root.popup;
        }
      },

      methods: {
      }
    }
</script>
