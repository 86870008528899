<template>
  <section id="featured-articles" class="row text-center">
    <h1 class="title-underline">Announcement Hub</h1>
    <div v-if="!loading">
      <div class="article-group col-md-10 col-md-offset-1">
        <div class="featured-article col-sm-12 col-md-4" v-for="(article, index) in articles.data" :key="index">
          <a :href="article.url">
            <div class="thumbnail">
              <img :src="ImageResize(article.featured_image_url)" :alt="article.title">
              <div class="caption">
                <h3>{{ article.title }}</h3>
                <p v-html="article.body.substring(0, 100) + '...'"></p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="article-cta col-md-12">
        <h3>
          <a href="/news" class="btn btn-link">View All Announcements <i class="fa fa-long-arrow-right"
              aria-hidden="true"></i></a>
        </h3>
      </div>
    </div>

    <div class="text-center" v-else>
      <Spinner color="#3982c4" />
    </div>
  </section>
</template>

<script>
import images from '../../../../../mixins/images';
import Spinner from '../../../../Spinner.vue';

export default {
  name: 'featured-articles',

  mixins: [
    images
  ],

  components: {
    Spinner
  },

  data() {
    return {
      loading: false,
      articles: [],
    }
  },

  async created() {
    let articleUrl = `/api/proxify/domains/prismatic/news?limit=3&orderby=published_at&orderdir=desc`;
    this.loading = true;

    await axios
      .get(articleUrl)
      .then(({ data: response }) => {
        this.articles = response;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
  }
}
</script>

<style scoped lang="scss">
#featured-articles {
  margin-bottom: 3%;

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.0rem;
  }

  .article-group {
    display: flex;

    .featured-article {
      margin-top: 20px;
      flex: 1;

      a {
        height: 100%;
        display: grid;

        .thumbnail {
          box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
          margin: 0 10px;
          padding: 1%;
          border: none;
          border-radius: 6px;

          p {
            margin-top: 5%;
          }

          img {
            max-height: 250px;
            min-height: 250px;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .article-cta {
    margin-top: 20px;

    a {
      font-size: 2rem;
      letter-spacing: 1rem;
      text-decoration: none;

      .fa-long-arrow-right {
        transition: transform .3s ease-in-out;
      }
    }

    a:hover {
      .fa-long-arrow-right {
        transform: translate(5px, 0);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .article-group {
      padding: 0;
      display: inherit;

      .featured-article {
        padding: 0;

        .thumbnail {
          margin: 0;
        }
      }
    }

    .article-cta {
      a {
        letter-spacing: inherit;
      }
    }
  }
}
</style>