<template>
  <section id="featured-about-us" class="row">
    <div class="col-md-10 col-md-offset-1">
      <h2 data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" data-aos="fade-right"><i
          class="fa fa-hand-o-right" aria-hidden="true"></i> Customers' #1 Choice in Powder Coating</h2>
      <p data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" data-aos="fade-left">Founded over 35 years
        ago, NIC INDUSTRIES INC.<sup>®</sup>'s vision was to manufacture coating products that would truly
        have a global impact. NIC now manufactures industry-leading coatings that are distributed and sold in nearly 90
        countries around the world. CERAKOTE<sup>®</sup>, <span v-html="$root.domainTrademark"></span>, and Performance Materials operate as separate divisions
        of NIC which has grown to be included in the top 25 largest paint and coating manufacturers in the United
        States. NIC's customer base is as wide as its geographical impact, with applications in technology, aerospace,
        sporting goods, and the automotive industry.</p>
      <!-- <a href="/about-us" class="btn btn-blue" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true"
        data-aos="fade-right">Take a look</a> -->
    </div>
  </section>
</template>
<script>
export default {
  name: 'featured-about-us',
}
</script>

<style scoped lang="scss">
#featured-about-us {
  padding: 3% 5%;
  background-color: #393939;
  color: #fbfbfb;

  h2 {
    color: #fbfbfb;
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}
</style>