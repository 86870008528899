// AOS (scroll effects) config: http://michalsnik.github.io/aos/
import AOS from 'aos';

export default function autoload() {

  require('./bootstrap');
  require('./functions');
  AOS.init({
    disable: 'mobile'
  });

  /****************************************************************************************
   * TODO: Move components that are not needed in many places to relevant component imports
   *
   * i.e. account-card should be imported in it's parent component like the following:
   *
   * import accountCard from './components/user/AccountCard.vue';
   *
   * export default {
   *   ...,
   *   components: {
   *     accountCard
   *   },
   *   ...
   * }
   ****************************************************************************************/

  Vue.prototype.dataLayer = window.dataLayer;
  Vue.component('forgot-password', require('./components/authentication/ForgotPassword.vue').default);
  Vue.component('reset-password', require('./components/authentication/ResetPassword.vue').default);
  Vue.component('user-reset-password', require('./components/authentication/UserResetPassword.vue').default);
  Vue.component('sso-finish-account-creation', require('./components/authentication/SsoFinishAccountCreation.vue').default);
  Vue.component('modal', require('./components/modals/Modal.vue').default);
  Vue.component('information-modal', require('./components/modals/InformationModal.vue').default);
  Vue.component('notifications', require('./components/Notifications.vue').default);
  Vue.component('footer-content', require('./components/layout/Footer.vue').default);
  Vue.component('header-content', require('./components/layout/Header.vue').default);
}