<!--
  /**
    * *NAME: Category Tabs
    * *PURPOSE: handles the displaying of the forgot email form.
    * *ASSOCIATED FILES:
    * *NOTES:
    */
-->

<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <h2>Forgot Password</h2>
        <h3>
          <a href="#" @click.prevent="$parent.$emit('change-modal', 'signin')">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            Back to Sign in
          </a>
        </h3>
      </div>

      <!-- /form-group -->
      <div class="msgBlock alert" v-if="response.type" :class="`alert-${response.type}`" style="float:left">
        {{ response.message }}
      </div>

      <div class="form-group forgotPassword" :class="{ 'has-error': errors.has('email') }">
        <label for="email">Email Address</label>
      <input type="text" v-validate="'required|email'" name="email" v-model="forgotPasswordForm.email" id="email" class="form-control" autocomplete="username" />
        <span v-show="errors.has('email')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errors.first("email") }}</span>
      </div>

      <!-- /form-group -->
      <div class="form-group forgotPassword text-center">
        <button type="submit" class="btn btn-block btn-orange" :disabled="errors.any() || forgotPasswordForm.processing">
          Submit
          <i class="fa" v-bind:class="{ 'fa-spinner fa-spin': forgotPasswordForm.processing, 'fa-arrow-right': !forgotPasswordForm.processing }" aria-hidden="true"></i>
        </button>
      </div>
      <!-- /form-group -->
    </form>
  </div>
</template>

<script>
  const url = `/api/proxify/account/reset`;

  export default {
    name: "forgot-password",

    data() {
      return {
        forgotPasswordForm: new Form({
          email: "",
        }),
        response: {},
      };
    },

    methods: {
      submitForm() {
        this.$validator
          .validateAll()
          .then((success) => {
            if (!success) {
              return;
            }
            this.forgotPasswordForm
              .post(url)
              .then((response) => {
                this.response = { type: "success", message: response.message };
                this.$nextTick(() => {
                  this.errors.clear();
                });
                $(".forgotPassword").css("display", "none");
              })
              .catch((response) => {
                this.response = { type: "danger", message: response.message };
              });
          })
          .catch(() => {
            // Be silent
          });
      },
    },
  };
</script>

<style scoped>
  .form-group > h2 {
    width: 38%;
  }
</style>
