import checkoutService from '../api/checkoutAPI';

const baseShippingErrorMessage = 'There appears to be a problem with this shipping address. ';
const baseBillingErrorMessage = 'There appears to be a problem with this billing address. ';

export const checkoutModule = {
  state: {
    general: {
      loadingCheckoutData: false,
      loadingAddresses: true,
      orderFailed: false,
      orderFailureMessage: '',
      customerComment: '',
      poNumber: '',
      signUpForNewsLetter: false,
      whenWillMyOrderArrive: false
    },
    checkoutData: window._PR?.checkoutData || {},
    shipping: {
      shippingAddressError: false,
      selectedAddress: null,
      errorMessage: baseShippingErrorMessage
    },
    billing: {
      billingSameAsShipping: false,
      billingAddressError: false,
      selectedAddress: null,
      previousAddress: null,
      errorMessage: baseBillingErrorMessage
    },
    shippingOptions: {
      loadingShippingOptions: false,
      isOverweight: false,
      forceDistributor: false,
      calculatingShipping: false,
      calculatingSwatchShipping: false,
      selectedDistributor: false,
      areShippingOptionsOutdated: false,
      areShippingOptions: false,
      areSwatchShippingOptions: false,
      overweightAcknowledged: false,
      swatchOptions: [],
      selectedNormal: '',
      selectedNormalFull: {},
      selectedSwatch: '',
      selectedSwatchFull: {}
    },
    payment: {
      savingPaymentOption: false,
      loadingPaymentOptions: true,
      savePaymentOption: false,
      purchaseFailed: false,
      paymentFailureMessage: '',
      selectedPaymentOption: '',
      cvv: ''
    },
    accountInfo: {
      accountInfoFailed: false,
      accountInfoFailedMessage: '',
      hasCompany: false,
      phoneNumber: '',
      primaryEmail: '',
      companyName: '',
      signUpCountry: '',
    },
  },
  getters: {
    //region Utility Getters
    formattedAddress: state => addressToFilter => {
      let address = {};

      if (typeof addressToFilter === 'string') {
        address = state[addressToFilter].selectedAddress;
      } else {
        address = addressToFilter;
      }

      if (!address) { return {} }
      if (!address.id) { return address }

      let country = address.country ? address.country.code : address.country_code;
      let region = address.region ? address.region.code : address.region_code;

      let newAddress = {
        postal_code: address.postal_code,
        country_code: country,
        region_code: region,
        type: address.type,
        name: {
          given_name: address.name.given_name,
          middle_name: address.name.middle_name,
          family_name: address.name.family_name
        },
        street_address: address.street_address,
        street_address_2: address.street_address_2,
        city:  address.city
      };

      if (addressToFilter === 'shipping') {
        newAddress['phone_number'] = address.phone_number;
      }

      return newAddress;
    },
    isShippingRestricted: state => {
      return state.checkoutData.order_items?.some(item => item.is_restricted);
    },
    //endregion

    //region General Getters
    whenWillMyOrderArrive: state => state.general.whenWillMyOrderArrive,
    loadingCheckoutData: state => state.general.loadingCheckoutData,
    loadingAddresses: state => state.general.loadingAddresses,
    customerComment: state => state.general.customerComment,
    poNumber: state => state.general.poNumber,
    orderFailed: state => state.general.orderFailed,
    orderFailureMessage: state => state.general.orderFailureMessage,
    signUpForNewsLetter: state => state.general.signUpForNewsLetter,
    //endregion

    //region Order Getters
    checkoutId: state => state.checkoutData.id,
    orderItems: state => state.checkoutData.order_items,
    shippingAmount(state) {
      if (state.shippingOptions.isOverweight) {
        return 'Overweight';
      }

      return state.checkoutData.shipping
           ? state.checkoutData.shipping.formatted
           : '$0.00';
    },
    couponCode: state => state.checkoutData.coupon_code,
    appliedDiscounts: state => state.checkoutData.applied_discounts,
    savings(state) {
      let backup = {
        amount: 0,
        formatted: '$0.00'
      };

      return state.checkoutData.savings
           ? state.checkoutData.savings
           : backup;
    },
    taxInfo: state => state.checkoutData.tax_info,
    taxAmount(state) {
      return state.checkoutData.tax
           ? state.checkoutData.tax.formatted
           : '$0.00';
    },
    subtotal(state) {
      return state.checkoutData.items_total_before_discount
           ? state.checkoutData.items_total_before_discount.formatted
           : '$0.00';
    },
    grandTotal(state) {
      return state.checkoutData.total
           ? state.checkoutData.total.formatted
           : '$0.00';
    },
    //endregion

    //region Shipping Address Getters
    shippingAddressError: state => state.shipping.shippingAddressError,
    selectedShippingAddress: state => state.shipping.selectedAddress,
    shippingErrorMessage: state => state.shipping.errorMessage,
    //endregion

    //region Billing Address Getters
    billingSameAsShipping: state => state.billing.billingSameAsShipping,
    billingAddressError: state => state.billing.billingAddressError,
    selectedBillingAddress: state => state.billing.selectedAddress,
    previousBillingAddress: state => state.billing.previousAddress,
    billingErrorMessage: state => state.billing.errorMessage,
    //endregion

    //region Shipping Options Getters
    loadingShippingOptions: state => state.shippingOptions.loadingShippingOptions,
    isOverweight: state => state.shippingOptions.isOverweight,
    forceDistributor: state => state.shippingOptions.forceDistributor,
    calculatingShipping: state => state.shippingOptions.calculatingShipping,
    calculatingSwatchShipping: state => state.shippingOptions.calculatingSwatchShipping,
    selectedDistributor: state => state.shippingOptions.selectedDistributor,
    swatchShippingOptions: state => state.shippingOptions.swatchOptions,
    selectedShippingOption: state => state.shippingOptions.selectedNormal,
    selectedFullShippingOption: state => state.shippingOptions.selectedNormalFull,
    selectedSwatchShippingOption: state => state.shippingOptions.selectedSwatch,
    selectedFullSwatchShippingOption: state => state.shippingOptions.selectedSwatchFull,
    areShippingOptionsOutdated: state => state.shippingOptions.areShippingOptionsOutdated,
    areShippingOptions: state => state.shippingOptions.areShippingOptions,
    areSwatchShippingOptions: state => state.shippingOptions.areSwatchShippingOptions,
    overweightAcknowledged: state => state.shippingOptions.overweightAcknowledged,
    //endregion

    //region Payment Option Getters
    loadingPaymentOptions: state => state.payment.loadingPaymentOptions,
    savingPaymentOption: state => state.payment.savingPaymentOption,
    savePaymentOption: state => state.payment.savePaymentOption,
    selectedPaymentOption: state => state.payment.selectedPaymentOption,
    purchaseFailed: state => state.payment.purchaseFailed,
    paymentFailureMessage: state => state.payment.paymentFailureMessage,
    cvv: state => state.payment.cvv,
    //endregion

    //region Account Info Getters
    phoneNumber: state => state.accountInfo.phoneNumber,
    primaryEmail: state => state.accountInfo.primaryEmail,
    companyName: state => state.accountInfo.companyName,
    signUpCountry: state => state.accountInfo.signUpCountry,
    hasCompany: state => state.accountInfo.hasCompany,
    accountInfoFailed: state => state.accountInfo.accountInfoFailed,
    accountInfoFailedMessage: state => state.accountInfo.accountInfoFailedMessage,
    //endregion
  },
  mutations: {
    //region General Mutations
    setWhenWillMyOrderArrive(state,value) {
      state.general.whenWillMyOrderArrive = value;
    },
    setLoadingCheckoutData(state, value) {
      state.general.loadingCheckoutData = value;
    },
    setLoadingAddresses(state, value) {
      state.general.loadingAddresses = value;
    },
    setCustomerComment(state, value) {
      state.general.customerComment = value;
    },
    setPoNumber(state, value) {
      state.general.poNumber = value;
    },
    setOrderFailed(state, value) {
      state.general.orderFailed = value;
    },
    setOrderFailureMessage(state, value) {
      state.general.orderFailureMessage = value;
    },
    setSignUpForNewsLetter(state, value) {
      state.general.signUpForNewsLetter = value;
    },
    //endregion

    //region Order Getters
    setCheckoutData(state, value) {
      state.checkoutData = value;
    },
    //endregion

    //region Shipping Address Mutations
    setShippingAddressError(state, value) {
      state.shipping.shippingAddressError = value;
    },
    setSelectedShippingAddress(state, value) {
      state.shipping.selectedAddress = value;
    },
    setShippingErrorMessage(state, value) {
      state.shipping.errorMessage += ' ' + value;
    },
    resetShippingErrorMessage(state) {
      state.shipping.errorMessage = baseShippingErrorMessage;
    },
    //endregion

    //region Billing Address Mutations
    toggleBillingSameAsShipping(state) {
      state.billing.billingSameAsShipping = !state.billing.billingSameAsShipping;
    },
    setBillingSameAsShipping(state, value) {
      state.billing.billingSameAsShipping = value;
    },
    setShowBillingAddressSelector(state, value) {
      state.billing.showBillingAddressSelector = value;
    },
    setBillingAddressError(state, value) {
      state.billing.billingAddressError = value;
    },
    setPreviousBillingAddress(state, value) {
      state.billing.previousAddress = value;
    },
    setSelectedBillingAddress(state, value) {
      state.billing.selectedAddress = value;
    },
    setBillingErrorMessage(state, value) {
      state.billing.errorMessage += value;
    },
    resetBillingErrorMessage(state) {
      state.billing.errorMessage = baseBillingErrorMessage;
    },
    //endregion

    //region Shipping Options Mutations
    setLoadingShippingOptions(state, value) {
      state.shippingOptions.loadingShippingOptions = value;
    },
    setIsOverweight(state, value) {
      state.shippingOptions.isOverweight = value;
    },
    setForceDistributor(state, value) {
      state.shippingOptions.forceDistributor = value;
    },
    setCalculatingShipping(state, value) {
      state.shippingOptions.calculatingShipping = value;
    },
    setCalculatingSwatchShipping(state, value) {
      state.shippingOptions.calculatingSwatchShipping = value;
    },
    setSelectedDistributor(state, value) {
      state.shippingOptions.selectedDistributor = value;
    },
    setAvailableSwatchShippingOptions(state, value) {
      state.shippingOptions.swatchOptions = value;
    },
    setSelectedShippingOption(state, value) {
      state.shippingOptions.selectedNormal = value;
    },
    setSelectedFullShippingOption(state, value) {
      state.shippingOptions.selectedNormalFull = value;
    },
    setSelectedSwatchShippingOption(state, value) {
      state.shippingOptions.selectedSwatch = value;
    },
    setSelectedFullSwatchShippingOption(state, value) {
      state.shippingOptions.selectedSwatchFull = value;
    },
    setAreShippingOptionsOutdated(state, value) {
      state.shippingOptions.areShippingOptionsOutdated = value;
    },
    setAreShippingOptions(state, value) {
      state.shippingOptions.areShippingOptions = value;
    },
    setAreSwatchShippingOptions(state, value) {
      state.shippingOptions.areSwatchShippingOptions = value;
    },
    setOverweightAcknowledged(state, value) {
      state.shippingOptions.overweightAcknowledged = value;
    },
    //endregion

    //region Payment Option Mutations
    setSelectedPaymentOption(state, value) {
      state.payment.selectedPaymentOption = value;
    },
    setLoadingPaymentOptions(state, value) {
      state.payment.loadingPaymentOptions = value;
    },
    setSavingPaymentOption(state, value) {
      Vue.set(state.payment, 'savingPaymentOption', value);
    },
    setSavePaymentOption(state, value) {
      state.payment.savePaymentOption = value;
    },
    setPurchaseFailed(state, value) {
      state.payment.purchaseFailed = value;
    },
    setPaymentFailureMessage(state, value) {
      state.payment.paymentFailureMessage = value;
    },
    setCvv(state, value) {
      state.payment.cvv = value;
    },
    //endregion

    //region Account Info Mutations
    setPhoneNumber(state, value) {
      state.accountInfo.phoneNumber = value;
    },
    setPrimaryEmail(state, value) {
      state.accountInfo.primaryEmail = value;
    },
    setHasCompany(state, value) {
      state.accountInfo.hasCompany = value;
    },
    setCompanyName(state, value) {
      state.accountInfo.companyName = value;
    },
    setSignUpCountry(state, value) {
      state.accountInfo.signUpCountry = value;
    },
    setAccountInfoFailed(state, value) {
      state.accountInfo.accountInfoFailed = value;
    },
    setAccountInfoFailedMessage(state, value) {
      state.accountInfo.accountInfoFailedMessage = value;
    }
    //endregion
  },
  actions: {
    checkSameAsShipping({ commit, dispatch, getters }) {
      commit('toggleBillingSameAsShipping');

      if (getters.billingSameAsShipping) {
        let address = _.cloneDeep(getters.formattedAddress('shipping'));

        address.type = 'billing';
        delete address.phone_number;

        dispatch('selectBillingAddressAction', address);
      } else if (getters.previousBillingAddress) {
        dispatch('selectBillingAddressAction', getters.previousBillingAddress);
      } else {
        commit('setSelectedBillingAddress', null);
      }
    },
    async selectShippingAddressAction({ commit, dispatch, getters }, address, editAddress = false) {
      commit('setAreShippingOptionsOutdated', false);

      if (editAddress) {
        commit('setSelectedShippingAddress', address);
        return;
      }

      commit('setSelectedShippingAddress', address);
      commit('setLoadingShippingOptions', true);

      if (getters.billingSameAsShipping) {
        commit('setBillingSameAsShipping', false);
        dispatch('checkSameAsShipping');
      }

      let payload = {
        shipping_address: getters.formattedAddress('shipping')
      };

      try {
        let response = await checkoutService.update(getters.checkoutId, payload);

        // Shipping options are outdated now
        commit('setAreShippingOptionsOutdated', true);
        commit('setShippingAddressError', false);
        commit('resetShippingErrorMessage', true);
        commit('setAreSwatchShippingOptions', false);
        commit('setAreShippingOptions', false);
        commit('setCheckoutData', response.data);
        commit('setLoadingShippingOptions', false);
      } catch(error) {
        const responseCode = error.response.data.code;
        const responseMessage = error.response.data.message;

        commit('setShippingAddressError', responseCode !== 200);
        commit('setShippingErrorMessage', responseMessage);
        commit('setLoadingShippingOptions', false);
      }
    },
    async selectBillingAddressAction({ commit, getters }, address) {
      let payload = {
        billing_address: getters.formattedAddress(address)
      };

      commit(`setSelectedBillingAddress`, address);

      try {
        await checkoutService.update(getters.checkoutId, payload);
      } catch(error) {
        const apiMessage = error.response.data.message;
        commit('setBillingAddressError', apiMessage.includes('billing'));
      }
    },

    async selectNormalShippingOption({ commit, getters }, option) {
      let optionCode = option.code;
      if (!optionCode || optionCode === getters.selectedShippingOption) {
        return;
      }

      commit('setOverweightAcknowledged', optionCode === 'contact_nic');
      commit('setSelectedDistributor', optionCode === 'local_distributor');
      commit('setSelectedShippingOption', optionCode);
      commit('setCalculatingShipping', true);

      if (optionCode !== 'local_distributor' && optionCode !== 'contact_nic') {
        commit('setSelectedFullShippingOption', option);
      }

      let shippingPayload = {
        shipping_option_code: getters.selectedShippingOption
      };

      try {
        let response = await checkoutService.update(getters.checkoutId, shippingPayload);
        commit('setCheckoutData', response.data);
      } finally {
        commit('setCalculatingShipping', false);
      }
    },
    async selectSwatchShippingOption({ commit, getters }, swatchOption) {
      let swatchShippingOption = swatchOption.code;
      if (!swatchShippingOption || swatchShippingOption === getters.selectedSwatchShippingOption) {
        return;
      }

      commit('setCalculatingSwatchShipping', true);
      commit('setSelectedSwatchShippingOption', swatchShippingOption);
      commit('setSelectedFullSwatchShippingOption', swatchOption);

      let shippingPayload = {
        swatch_order_shipping_option_code: getters.selectedSwatchShippingOption
      };

      try {
        let response = await checkoutService.update(getters.checkoutId, shippingPayload);
        commit('setCheckoutData', response.data);
      } finally {
        commit('setCalculatingSwatchShipping', false);
      }
    },

    setAccountInfoFailedAction({ commit }, { isFailed, message }) {
      commit('setAccountInfoFailed', isFailed);
      commit('setAccountInfoFailedMessage', message);
    },
  }
};
