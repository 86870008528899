const ProtocolTest = new RegExp("(http[s]?://)");
const FacebookTest = new RegExp("(facebook.com)");
const InstagramTest = new RegExp("(instagram.com)");
const TwitterTest = new RegExp("(twitter.com)");

export default {
  methods: {
    /**
     * @method URLParamString
     * @author Sebastian Inman
     * @modified June 03 2020 "Created"
     * @description Converts an object into a URL query string.
     * @param {Object} params - The type of URL being modified (website, facebook, instagram)
     * @return {String}
     */

    URLParamString(params = {}) {
      let query = `?`;
      for (let [key, value] of Object.entries(params)) {
        query += `${key}=${value}&`;
      }
      query = query.replace(/&\s*$/, "");

      return query;
    },

    /**
     * Normalizes links by ensuring the link is a string, converting it to all lowercase,
     * and removing any trailing slashes.
     * @param {string} link
     * @returns {string}
     */

    NormalizeLink(link) {
      return link
        ? link
            .toString()
            .toLowerCase()
            .replace(/\/$/, "")
        : "";
    },

    FormatPhone(link) {
      return link ? link.replace(/[^0-9]/g, "") : "";
    },

    /**
     * Converts the provided string into a URL friendly slug.
     * Makes the string all lowercase, removes unwanted special characters, and replaces
     * all whitespace with dashes.
     * @param {string} text
     * @returns {string}
     */

    URLSlugify(text) {
      return this.NormalizeLink(text)
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
    },

    /**
     * Validates the provided link by checking if the string begins with "http" or "https".
     * @param {string} link
     * @returns {boolean}
     */

    ValidProtocol(link) {
      return ProtocolTest.test(link);
    },

    /**
     * Validates the provided link by checking if the string begins with "facebook.com".
     * @param {string} link
     * @returns {boolean}
     * @constructor
     */

    ValidFacebookLink(link) {
      return FacebookTest.test(link);
    },

    /**
     * Validates the provided link by checking if the string begins with "instagram.com".
     * @param {string} link
     * @returns {boolean}
     * @constructor
     */

    ValidInstagramLink(link) {
      return InstagramTest.test(link);
    },

    /**
     * Validates the provided link by checking if the string begins with "twitter.com".
     * @param {string} link
     * @returns {boolean}
     * @constructor
     */

    ValidTwitterLink(link) {
      return TwitterTest.test(link);
    },

    PhoneLink(link) {
      link = this.NormalizeLink(link);
      link = `tel:${this.FormatPhone(link)}`;

      return link;
    },

    GenericLink(link) {
      link = this.NormalizeLink(link);
      link = !this.ValidProtocol(link) ? `http://${link}` : link;

      return link;
    },

    FacebookLink(link) {
      link = this.NormalizeLink(link);
      link = !this.ValidFacebookLink(link) ? `facebook.com/${link}` : link;
      link = !this.ValidProtocol(link) ? `https://${link}` : link;

      return link;
    },

    InstagramLink(link) {
      link = this.NormalizeLink(link);
      link = !this.ValidInstagramLink(link) ? `instagram.com/${link}` : link;
      link = !this.ValidProtocol(link) ? `https://${link}` : link;

      return link;
    },

    TwitterLink(link) {
      link = this.NormalizeLink(link);
      link = !this.ValidTwitterLink(link) ? `twitter.com/${link}` : link;
      link = !this.ValidProtocol(link) ? `https://${link}` : link;

      return link;
    },

    DirectionsLink({ lat, lon }) {
      return `https://maps.google.com/maps?daddr=${lat},${lon}`;
    }
  }
};
