<template>
  <main id="home-layout" class="container-fluid">
    <FeaturedHeroBanner @imgLoading="setImageLoading" />
    <FeaturedIntro :imgLoading="imgLoading" />
    <FeaturedBrands />
    <FeaturedProducts @add-swatch-to-cart="addSwatchToCart" />
    <FeaturedQuotes />
    <FeaturedArticles v-if="false" />
    <FeaturedVideos />
    <FeaturedAboutUs />
    <FeaturedEmailNewsletter />
    <FeaturedIndustries v-if="false" />
  </main>
</template>

<script>
import FeaturedHeroBanner from './layout_components/FeaturedHeroBanner.vue';
import FeaturedIntro from './layout_components/FeaturedIntro.vue';
import FeaturedQuotes from './layout_components/FeaturedQuotes.vue';
import FeaturedIndustries from './layout_components/FeaturedIndustries.vue';
import FeaturedProducts from './layout_components/FeaturedProducts.vue';
import FeaturedBrands from './layout_components/FeaturedBrands.vue';
import FeaturedArticles from './layout_components/FeaturedArticles.vue';
import FeaturedAboutUs from './layout_components/FeaturedAboutUs.vue';
import FeaturedEmailNewsletter from './layout_components/FeaturedEmailNewsletter.vue';
import FeaturedVideos from './layout_components/FeaturedVideos.vue';

export default {
  name: 'home-layout',

  components: {
    FeaturedHeroBanner,
    FeaturedIntro,
    FeaturedQuotes,
    FeaturedIndustries,
    FeaturedProducts,
    FeaturedBrands,
    FeaturedArticles,
    FeaturedAboutUs,
    FeaturedEmailNewsletter,
    FeaturedVideos
  },

  data() {
    return {
      imgLoading: true,
    }
  },

  methods: {
    setImageLoading(imgLoading) {
      this.imgLoading = imgLoading;
    },
    addSwatchToCart(swatch) {
      this.$emit("add-swatch-to-cart", swatch);
    },
  }
}
</script>

<style scoped lang="scss">
#home-layout {
  overflow: hidden;
  max-width: max-content;

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}
</style>