<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="85px"
    height="85px" viewBox="0 0 110 115" style="enable-background:new 0 0 180.41113 180.41162;" xml:space="preserve">
    <path class="logo0" d="M18.72949,0.5L0.6167,86.13867h93.22168L111.95117,0.5H18.72949z M49.61279,17.2251
      c0.38721-1.7959,2.15723-3.25195,3.95313-3.25195s2.9375,1.45605,2.55029,3.25195s-2.15723,3.25195-3.95313,3.25195
      C50.36768,20.47705,49.22559,19.021,49.61279,17.2251z M46.79297,30.30176c0.38721-1.7959,2.15723-3.25195,3.95313-3.25195
      s2.9375,1.45605,2.55029,3.25195s-2.15674,3.25146-3.95264,3.25146S46.40576,32.09766,46.79297,30.30176z M50.47656,43.37842
      c-0.38721,1.79639-2.15674,3.25146-3.95264,3.25146s-2.93799-1.45508-2.55078-3.25146
      c0.38721-1.7959,2.15723-3.25146,3.95313-3.25146S50.86377,41.58252,50.47656,43.37842z M40.18359,17.2251
      c0.32471-1.50635,1.80957-2.72754,3.31592-2.72754s2.46387,1.22119,2.13916,2.72754s-1.80908,2.72754-3.31543,2.72754
      S39.85889,18.73145,40.18359,17.2251z M37.36377,30.30176c0.32471-1.50635,1.80957-2.72754,3.31592-2.72754
      s2.46436,1.22119,2.13916,2.72754c-0.32471,1.50635-1.80908,2.72754-3.31543,2.72754S37.03906,31.80811,37.36377,30.30176z
      M37.85986,40.65088c1.50635,0,2.46436,1.22119,2.13916,2.72754c-0.32471,1.50635-1.80908,2.72705-3.31543,2.72705
      s-2.46436-1.2207-2.13965-2.72705C34.86914,41.87207,36.35352,40.65088,37.85986,40.65088z M30.99707,17.2251
      c0.22461-1.0415,1.25049-1.88525,2.29199-1.88525c1.04102,0,1.70313,0.84375,1.479,1.88525
      c-0.22461,1.0415-1.25098,1.88525-2.29199,1.88525C31.43457,19.11035,30.77246,18.2666,30.99707,17.2251z M23.48877,69.53223
      c-0.22461,1.04102-1.25049,1.88477-2.29199,1.88477s-1.70361-0.84375-1.479-1.88477
      c0.22461-1.04199,1.25098-1.88574,2.29248-1.88574C23.05127,67.64648,23.71338,68.49023,23.48877,69.53223z M26.83643,45.26367
      c-1.0415,0-1.70361-0.84375-1.479-1.88525s1.25098-1.88525,2.29199-1.88525c1.0415,0,1.70361,0.84375,1.479,1.88525
      S27.87744,45.26367,26.83643,45.26367z M34.35986,69.53223c-0.3252,1.50586-1.80957,2.72754-3.31592,2.72754
      s-2.46436-1.22168-2.13916-2.72754c0.32471-1.50684,1.80908-2.72754,3.31543-2.72754S34.68457,68.02539,34.35986,69.53223z
      M37.1792,56.45508c-0.32471,1.50586-1.80908,2.72754-3.31543,2.72754s-2.46436-1.22168-2.13916-2.72754
      c0.32471-1.50586,1.80908-2.72754,3.31543-2.72754S37.50439,54.94922,37.1792,56.45508z M44.8374,69.53223
      c-0.3877,1.7959-2.15723,3.25098-3.95313,3.25098s-2.93799-1.45508-2.55078-3.25098c0.3877-1.7959,2.15723-3.25195,3.95313-3.25195
      S45.22461,67.73633,44.8374,69.53223z M43.7041,59.70703c-1.7959,0-2.93799-1.45605-2.55078-3.25195
      c0.3877-1.7959,2.15723-3.25195,3.95313-3.25195s2.93799,1.45605,2.55029,3.25195C47.26953,58.25098,45.5,59.70703,43.7041,59.70703
      z M88.91455,18.45996L82.1958,49.61719c-0.68262,3.16504-3.80176,5.73047-6.9668,5.73047H62.74658l6.22119-8.64551h5.09961
      l5.46143-25.32764H67.10352L55.7749,73.91016h-8.75684l13.19287-61.18164h24.20801
      C87.58447,12.72852,89.59668,15.29443,88.91455,18.45996z" />
  </svg>
</template>

<script>
export default {
  name: "prisLogo"
}
</script>

<style>
.logo0 {
  fill: white;
}
</style>