<template>
  <section id="featured-email-newsletter" class="row">
    <div class="newsletter-sign-up col-md-12">
      <div class="col-md-8 col-md-offset-2">
        <div class="col-md-6">
          <h2>
            Subscribe for {{ $root.domainName }} Updates
            <br>
            <small>Deals. Product Updates & More</small>
          </h2>
        </div>
        <div class="col-md-6">
          <!-- Begin Mailchimp Signup Form -->
          <div id="mc_embed_signup">
            <form
              action="https://nicindustries.us1.list-manage.com/subscribe/post?u=99fe9d627854feb5c257b8347&amp;id=9ee76ca792"
              method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
              target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <div class="mc-field-group form-group">
                  <input type="email" placeholder="* Email Address" name="EMAIL" class="required email form-control"
                    id="mce-EMAIL">
                </div>
                <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                  <input type="text" name="b_99fe9d627854feb5c257b8347_9ee76ca792" tabindex="-1" value="">
                </div>
                <div class="clear form-group">
                  <input type="submit" value="Sign up: It's free" name="subscribe" id="mc-embedded-subscribe"
                    class="button btn btn-black" style="width: 100%;">
                </div>
                <div class="indicates-required">
                  <span class="asterisk">* indicates required</span>
                </div>
              </div>
            </form>
          </div>
          <!--End mc_embed_signup-->
        </div>
      </div>
    </div>
    <div class="newsletter-cta-group text-center">
      <div class="newsletter-item">
        <div class="newsletter-cta">
          <h3>Companies that use {{ $root.domainName }}</h3>
          <a href="/brands" class="btn btn-blue">Explore now</a>
        </div>
      </div>
      <div class="newsletter-item">
        <div class="newsletter-cta">
          <h3>Want to know more about us?</h3>
          <a href="/about-us" class="btn btn-blue">Let's Go</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'featured-email-newsletter',
}
</script>

<style scoped lang="scss">
#featured-email-newsletter {
  background-color: #393939;

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h2,
  h3,
  small,
  button {
    color: #fbfbfb;
  }

  .newsletter-sign-up {
    background-color: #3982c4;
    padding: 3% 5%;
  }

  .newsletter-cta-group {
    display: flex;
    padding: 5% 15% 2% 15%;

    .newsletter-item {
      display: flex;
      width: 50%;
    }

    .newsletter-cta {
      border-radius: 6px;
      padding: 3% 3% 5% 3%;
      margin: 10px;
      background-color: #555;
      flex: 1;

      .btn {
        margin-top: 3%;
        width: 35%;
      }
    }
  }

  @media screen and (max-width: 1030px) {
    .newsletter-cta-group {
      display: initial;

      .newsletter-item {
        display: initial;

        .newsletter-cta {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>