export function findCorrectPricingOptionForQuantity(pricing, correctedAmount) {
    return pricing.filter(po => po.starting_quantity.value <= correctedAmount)
        .sort((p1, p2) => p2.starting_quantity.value - p1.starting_quantity.value)[0];
}

export function calculateLineItemPricing(product, quantity) {
    const pricingOption = findCorrectPricingOptionForQuantity(product.pricing || [], quantity);
    const unitPrice = pricingOption.price_per_base_quantity.amount / product.base_quantity.value;
    const total = quantity * unitPrice;

    const unitPriceFormatted = unitPrice.toFixed(2);
    const totalFormatted = total.toFixed(2);

    return {
        unitPrice,
        total,
        unitPriceFormatted,
        totalFormatted
    };
}
