export const cartModule = {
  state: {
    localCart: window._PR?.cart
      ? window._PR.cart
      : {
          discounts: [],
          items: [],
          items_total: [],
          items_total_before_discount: {
            amount: "0",
            currency: "USD",
            formatted: "$0",
          },
          savings: [],
          shipping: [],
          total: {
            amount: "0",
            currency: "USD",
            formatted: "$0",
          },
        },
    localItemJustAddedToCart: {
      product: {
        featured_image_url: "",
        name: "",
        sku: "",
        pricing: [
          {
            price_per_base_quantity: {
              formatted: "$0",
            },
          },
        ],
        quantity: {
          amount: "1",
          weight: {
            value: "1",
            unit: "lb",
          },
        },
      },
    },
    hasLocalCart: false,
  },

  getters: {
    localCart: (state) => state.localCart,
    localItemJustAddedToCart: (state) => state.localItemJustAddedToCart,
    hasLocalCart: (state) => state.hasLocalCart,
  },

  mutations: {
    setLocalCart(state, value) {
      state.localCart = value;
    },
    setHasLocalCart(state, value) {
      state.hasLocalCart = value;
    },
    setLocalItemJustAddedToCart(state, value) {
      state.localItemJustAddedToCart.product = value;
      // state.localCart.items.push(value);
      // localStorage.setItem('_local_cart', JSON.stringify(state.localCart));
    },
  },
};
