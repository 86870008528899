export default {

  data() {
    return {
      prefetched: false
    }
  },

  methods: {

    /**
     * Prefetches link data on a `MouseEvent`.
     * - This is useful for preloading pages that a user may navigate to next.
     * - This can improve the user experience by reducing the time it takes to load a page.
     * - This also caches the page in the browser, so it doesn't need to be fetched again.
     * @author Sebastian Inman <sinman@nicindustries.com>
     * @since December 3, 2024
     * @param {MouseEvent} event
     * @returns {void}
     */
    prefetch(event) {
      // If the event target is not an anchor element, return early.
      if (!(event.target instanceof HTMLAnchorElement)) return

      // If the link is already prefetched, return early.
      if (this.prefetched) return

      // Create the prefetch link element.
      const prefetch = document.createElement('link')
      prefetch.rel = event.target.href
      prefetch.as = 'document'

      // Append the prefetch link element to the document head.
      document.head.appendChild(prefetch)

      // Set the prefetched flag to true.
      this.prefetched = true
    }

  }

}
