<template>
  <div>
    <form action="" @submit.prevent="validateLoginForm">
      <div class="form-group title-group">
        <h2>Sign into your NIC INDUSTRIES INC® Account</h2>
      </div>

      <div class="msgblock alert" :class="`alert-${carriedOverMessage.type}`"
        v-if="carriedOverMessage && carriedOverMessage.action === 'confirm'">
        {{ carriedOverMessage.content }}
      </div>

      <div v-if="response && response.status === 401" class="alert msgblock invalidCredentials">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>
          {{ response.message }}. If you have an account with CERAKOTE<sup>®</sup>, please
          try logging in using your CERAKOTE<sup>®</sup> credentials or create a new account
          below.
        </span>
      </div>

      <div v-if="response && response.type && response.status !== 401" class="alert msgblock"
        :class="`alert-${response.type}`">
        {{ response.message }}
      </div>

      <div class="form-group" :class="{ 'has-error': errors.has('email') }">
        <label for="email">Email Address</label>
        <div>
          <input type="email" v-validate="'required|email'" name="email" placeholder="Email Address"
            v-model="formData.username" class="form-control" autocomplete="username" />
        </div>
        <span v-show="errors.has('email')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errors.first("email") }}</span>
      </div>

      <div class="form-group" :class="{ 'has-error': errors.has('password') }">
        <label for="password">Password</label>
        <div class="input-group">
          <input :type="showPassword ? 'text' : 'password'" v-validate="'required'" name="password"
            placeholder="Password" v-model="formData.password" class="form-control" autocomplete="current-password" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" @click="toggleShow">
              <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </span>
        </div>
        <span v-show="errors.has('password')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errors.first("password") }}</span>
      </div>

      <div class="form-group text-center">
        <button type="submit" class="btn btn-block btn-orange" :disabled="errors.any() || processing">
          Sign In
          <i class="fa" v-bind:class="{ 'fa-spinner fa-spin': processing, 'fa-arrow-right': !processing }"
            aria-hidden="true"></i>
        </button>
        <div class="forgot-password">
          <a href="#" @click.prevent="$parent.$emit('change-modal', 'forgot')"
            class="forgot-password btn-forgot-password">
            Forgot your password?
          </a>
        </div>
        <a href="#" class="btn btn-signup" @click.prevent="$parent.$emit('change-modal', 'signup')">
          Don't have an account? <span>Sign up</span>
        </a>
      </div>

      <div class="divider-sign-in"><span>OR SIGN IN WITH</span></div>

      <div class="form-group btns-sign-in">
        <a :href="socialLoginUrl('google')" target="_self" class="btn btn-block btn-google">
          <strong>Google</strong>
        </a>
        <a :href="socialLoginUrl('facebook')" target="_self" class="btn btn-block btn-facebook">
          <strong>Facebook</strong>
        </a>
      </div>

      <div class="form-group btns-sign-in mobile-sign-in">
        <a :href="socialLoginUrl('google')" target="_self" class="btn btn-block btn-google"><i class="fa fa-google-plus"
            aria-hidden="true"></i></a>
        <a :href="socialLoginUrl('facebook')" target="_self" class="btn btn-block btn-facebook"><i
            class="fa fa-facebook" aria-hidden="true"></i></a>
      </div>

      <p>By logging in, you agree to the {{ $root.domainName }} <a target="_blank" href="/terms-and-policies">Privacy Policy
          and Terms of Use</a></p>
    </form>
  </div>
</template>

<script>
/**
  * @file SignIn.vue
  * @summary handles the login modal content and necessary logic checks.
  * @external auth.js, app.js
  * @alias sign-in
*/

import userAndCart from '../../mixins/user-and-cart.js'

export default {
  name: "sign-in",

  mixins: [userAndCart],

  props: ['carriedOverMessage'],

  data() {
    return {
      formData: {
        username: "",
        password: "",
        grant_type: "password",
      },
      showPassword: false,
      processing: false,
      response: null,
    };
  },

  mounted() {
    if (this.carriedOverMessage) {
      const action = this.carriedOverMessage.action;

      if (action === "reset") {
        this.$parent.currentModalView = "reset";
        $('#logInLink').trigger('click');
      } else if (action === "delegate_registration") {
        this.$parent.currentModalView = "delegateRegistration";
      }
    }

    this.$nextTick(function () {
      if (window.appVariables.logged_in === true) {
        this.prepareDashboard();
      }
    });
  },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    socialLoginUrl(delegate = "google") {
      let fullpath = encodeURIComponent(window.location.href);

      return `${window.appVariables.sso_base_url}/oauth2/delegate-authorize?state=${fullpath}&response_type=code&client_id=${window.appVariables.api_client_id}&redirect_uri=${window.appVariables.app_url}%2Flogin%2Fverify&delegate=${delegate}`;
    },

    async validateLoginForm() {
      try {
        const success = await this.$validator.validateAll();

        if (!success) return;
        this.login();
        this.processing = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.processing = false;
      }
    },

    async login() {
      try {
        const url = "/api/proxify/oauth2/token";
        const payload = $.param(this.formData);
        const params = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const { data } = await axios.post(url, payload, params);

        if (data.success_code && data.success_code === "access_token_ok") {
          // 👇 unsure what this does...?
          // if (window._PR.request_uri) {
          //   window.location.href = window._PR.request_uri;
          // }

          this.formData.username = "";
          this.formData.password = "";
          this.processing = false;
          this.$nextTick(() => {
            this.errors.clear();
          });
          this.prepareDashboard();
        }
      } catch (error) {
        this.processing = false;
        this.response = {
          type: "danger",
          message: "",
        };

        if (error.response.status === 401) {
          this.response["status"] = 401;
          this.response.message = "Invalid Username or Password";
        } else if (error.response.status === 403) {
          this.processing = false;
          this.response.message =
            "This email address has not been verified yet. Verify your account using the email sent on account creation or call NIC at 1-866-774-7628.";
        } else if (error.response.status === 500) {
          this.response.message =
            "Something went wrong, Please refresh the page and try again";
        }
      }
    },

    prepareDashboard: function () {
      $("#login").modal("hide");
      this.getUser();
      this.getCart();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  .form-control {
    border-radius: 3px 0 0 3px !important;
  }
}

.login-signup .modal-body .btn,
.signup-country .modal-body .btn {
  padding: 16px;

  i {
    margin: 0 !important;
  }
}
</style>
