import { mapMutations } from "vuex";
import Cookie from "js-cookie";

export default {
  methods: {
    ...mapMutations(["setIsLoggedIn"]),

    signOut(redirect) {
      this.setIsLoggedIn(false);

      sessionStorage.removeItem("user");

      Cookie.remove("logged_in");
      Cookie.remove("prismatic_powders_proxify");
      Cookie.remove("prismatic_session");

      if (redirect) {
        window.location = "/sign-out";
      }
    },
  },
};
