<template>
  <nav id="main-nav" class="navbar navbar-default">
    <NotificationBar />
    <div id="navbar-header">
      <div class="box section-1">
        <a class="navbar-logo" href="/">
          <img src="/img/logo-brand.svg" :alt="`${$root.domainName} - Powder Coating Paint`"
            :title="`${$root.domainName} - Powder Coating`">
        </a>
        <a class="badge icon-label phone" href="tel:1-866-774-7628">1-866-774-7628</a>
      </div>
      <div class="box">
        <SiteSearch />
      </div>
      <div class="box section-3">
        <div class="btn-group">
          <a class="btn btn-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            type="button" title="1-866-774-7628">
            <i class="fa fa-phone fa-2x" aria-hidden="true"></i>
            <span class="badge icon-label">Call</span>
          </a>
          <ul class="dropdown-menu">
            <li><a href="tel:1-866-774-7628">1-866-774-7628</a></li>
          </ul>
        </div>
        <a href="#" data-target="#login" class="btn btn-link" type="button" title="Loading..."
          v-if="!$root.isLoggedIn && $root.loadingUserData">
          <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
          <span class="badge icon-label">Loading...</span>
        </a>
        <a href="#" data-toggle="modal" id="logInLink" data-target="#login" class="btn btn-link" type="button"
          title="Log In" v-else-if="!$root.isLoggedIn">
          <i class="fa fa-sign-in fa-2x" aria-hidden="true"></i>
          <span class="badge icon-label">Log In</span>
        </a>
        <div class="btn-group" v-else>
          <a class="btn btn-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            type="button">
            <i class="fa fa-user fa-2x" aria-hidden="true"></i>
            <span class="badge icon-label">Account</span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <h6 class="text-center" v-cloak>{{ $root.me.name.formatted_name }}</h6>
            </li>
            <li>
              <h6 class="text-center" v-cloak>{{ $root.me.customer_id }}</h6>
            </li>
            <li role="separator" class="divider"></li>
            <li><a href="/my-account">Your Account</a></li>
            <li>
              <a href="/sign-out" id="signOutHL" @click="initiateSignOut">
                Sign Out&nbsp;
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="btn-group">
          <a href="/cart" class="btn btn-link cart" type="button">
            <i class="fa fa-shopping-cart fa-2x" aria-hidden="true"></i>
            <span class="badge icon-label">Cart</span>
            <span class="badge cart-badge" v-show="$root.totalCartItems > 0">{{ $root.totalCartItems }}</span>
          </a>
        </div>
      </div>
    </div>
    <Navigation @open-menu="openMenu" />
    <slideout-panel @close-menu="closeMenu" />
  </nav>
</template>

<script>
import signOut from '../../mixins/sign_out'
import SiteSearch from './header_components/SiteSearch.vue';
import Navigation from './header_components/Navigation.vue';
import NotificationBar from './header_components/NotificationBar.vue';
// https://officert.github.io/vue-slideout-panel
import VueSlideoutPanel from 'vue2-slideout-panel';

export default {
  name: 'header-content',

  mixins: [
    signOut
  ],

  components: {
    Navigation,
    SiteSearch,
    NotificationBar,
    VueSlideoutPanel,
  },

  data() {
    return {
      displayMenu: false,
    }
  },

  methods: {
    openMenu(value) {
      this.displayMenu = value;

      if (this.displayMenu) {
        this.panelResult = this.$showPanel({
          component: Vue.component('menu-panel', require('./header_components/MenuPanel.vue').default),
          openOn: 'left',
          width: '375px',
          props: {}
        });
      }
    },

    closeMenu(value) {
      this.displayMenu = value;

      if (!this.displayMenu) {
        this.panelResult.hide();
      }
    },

    initiateSignOut() {
      this.signOut(true);
    }
  }
}
</script>

<style scoped lang="scss">
#main-nav {
  display: grid;

  #navbar-header {
    display: flex;
    width: 100%;
    padding: 5px 15%;
    margin: 0 auto;
    background-color: #393939;
    color: #fff;

    .box {
      display: flex;
      flex: 1 1 0;
      align-items: center;
      justify-content: center;
    }

    .section-1 {
      display: inline-grid;
      justify-content: flex-start;

      .phone {
        color: #fff;
        border-radius: 6px;
        text-align: center;
        font-size: 1rem;

        &:hover {
          color: #fff !important;
        }
      }
    }

    .section-3 {
      justify-content: flex-end;

      .dropdown-menu {
        a {
          font-size: 1.8rem;
        }
      }

      .cart {
        i {
          float: left;
        }
      }

      .btn-link {
        color: #fff !important;
        text-decoration: none;
        width: 80px;
        display: grid;

        i {
          float: left;
          display: grid;
        }

        .icon-label {
          font-size: 1rem;
          background-color: #777;
          border-radius: 6px;
        }

        .cart-badge {
          position: absolute;
          right: 10px;
          background-color: #ff5722;
          border-radius: 6px;
          width: max-content;
        }
      }

      .btn-link:hover {
        background-color: #a2a2a2;
        border-color: #adadad;
        border-radius: 4px;
      }
    }

    .navbar-logo {
      img {
        filter: invert(1);
        min-width: 120px;
        max-width: 120px;
      }
    }
  }
}
</style>