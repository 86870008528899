<template>
  <div class="product-card">

    <div class="product-card-img">

      <div v-if="product.discontinued" class="product-card-btn">
        <a class="btn btn-black product-btn" :href="product.url">
          Discontinued
        </a>
      </div>

      <div v-else-if="isApparel" class="product-card-btn">
        <a @mouseenter="prefetch" class="btn btn-blue product-btn" :href="product.url">
          View Details
        </a>
      </div>

      <div v-else class="product-card-btn">
        <button v-if="product.hero_only == 'true'" type="button" class="btn btn-alt-outline product-btn" disabled>
          <i aria-hidden="true" class="fa fa-phone"></i>
          Call for Availability
        </button>
        <button v-else-if="product.stock_level === 'long_term_out_of_stock'" type="button" value="Notify Me" class="btn btn-blue product-btn" @click.prevent="addProductToCart" :disabled="shouldDisableCartButtons">
          <i aria-hidden="true" class="fa fa-bell"></i>
          Notify Me
        </button>
        <button v-else-if="product.stock_level === 'out_of_stock'" type="button" value="Notify Me" class="btn btn-block btn-light-green product-btn" @click.prevent="addProductToCart" :disabled="shouldDisableCartButtons">
          <i v-if="productBeingAddedToCart" aria-hidden="true" class="fa fa-spin fa-spinner"></i>
          <i v-else aria-hidden="true" class="fa fa-shopping-cart"></i>
          <span v-if="productBeingAddedToCart">Adding to Cart</span>
          <span v-else>Add to Cart</span>
        </button>
        <button v-else type="button" value="Add to Cart" class="btn btn-light-green product-btn" @click.prevent="addProductToCart" :disabled="shouldDisableCartButtons">
          <i v-if="productBeingAddedToCart" aria-hidden="true" class="fa fa-spin fa-spinner"></i>
          <i v-else aria-hidden="true" class="fa fa-shopping-cart"></i>
          <span v-if="productBeingAddedToCart">Adding to Cart</span>
          <span v-else>Add to Cart</span>
        </button>
        <button
          class="btn btn-orange product-btn"
          v-if="showOrderFreeSwatchButton && orderFreeSwatchBtn"
          @click.prevent="addSwatchToCart"
          :disabled="shouldDisableCartButtons"
        >
          Order Free Swatch
        </button>
      </div>

      <a @mouseenter="prefetch" :href="product.url">
        <img v-if="productImageUrl" class="img-effect" :src="productImageUrl + '?size=200'" :alt="product.name" />
        <img v-else class="img-effect" src="/img/default-product-img.png?size=200" alt="image coming soon" />
      </a>
    </div>

    <a @mouseenter="prefetch" :href="product.url">
      <div class="product-card-details">
        <h3 class="product-title">{{ product.name }}</h3>
        <div class="color-match" v-if="colorMatch">matches {{ colorMatch.name }}</div>
        <span class="product-sku">{{ product.sku }}</span>
        <span class="product-price">{{ productPricePerUnit }}</span>
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cart from "../../../mixins/cart";
import product from "../../../mixins/product";
import prefetch from "../../../mixins/prefetch";

export default {
  name: "Product",

  mixins: [product, cart, prefetch],

  props: {
    product: {
      type: Object,
      required: true,
    },
    colorMatch: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      required: true,
    },
    orderFreeSwatchBtn: {
      type: Boolean,
      default: false,
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      productBeingAddedToCart: false,
      swatchBeingAddedToCart: false
    }
  },

    computed: {
      ...mapGetters([
        'imgLoading'
      ]),
      showOrderFreeSwatchButton() {
        if (!this.product.swatch_product) return false
        return this.product.swatch_product.enabled
          && !this.product.swatch_product.hero_only
          && !this.product.discontinued
          && !this.product.isAkzo;
      },
      currentShopPageUrl() {
        const currentPathArray = window.location.pathname.split('/');
        return currentPathArray[currentPathArray.length - 1];
      },
      isApparel() {
        const apparelCategories = ['pris_gear'];
        return this.product.categories?.filter(category => apparelCategories.includes(category.code)).length > 0;
      },
      shouldDisableCartButtons() {
        return this.swatchBeingAddedToCart
               || this.productBeingAddedToCart
               || this.cartIsBusy;
      },
      productPricePerUnit() {
        if (!this.product.pricing || !this.product.pricing.length) return 'NA';
        const pricing = this.product.pricing[0];
        const formattedPrice = pricing?.price_per_base_quantity.formatted ?? null;
        const unit = pricing?.starting_quantity.unit ?? null;
        return (formattedPrice && unit)
          ? `${formattedPrice}/${unit === 'item' ? 'ea' : 'lb'}`
          : 'NA';
      },
      productImageUrl() {
        if (!this.product.pricing) return this.product.image_url
        return this.product.featured_image_url
      }
  },

  methods: {

    async addSwatchToCart() {
      if (this.shouldDisableCartButtons) return;
      this.dataLayer.push({ 'sku': this.product.sku, 'event': 'swatchAddToCart' });
      this.swatchBeingAddedToCart = true;
      await this.isSearchPage
        ? this.$root.addSwatchToCart(this.product)
        : this.$emit("add-swatch-to-cart", this.product);
      this.swatchBeingAddedToCart = false;
    },

    async addProductToCart() {
      if (this.shouldDisableCartButtons) return;
      this.dataLayer.push({ 'sku': this.product.sku, 'event': 'productAddToCart' });
      this.productBeingAddedToCart = true;
      await this.addToCart(this.product);
      this.productBeingAddedToCart = false;
    },

    gtmProductClick(product) {
      // this is for GA4
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          item_list_id: undefined,
          item_list_name: undefined,
          currency: "USD",
          value: undefined,
          items: [
            {
              item_id: product.sku,
              item_name: product.name,
              affiliation: this.$root.me.customer_id
                ? this.$root.me.customer_id
                : undefined,
              coupon: undefined,
              currency: "USD",
              discount: undefined,
              index: this.index,
              item_brand: "Prismatic",
              item_category: product.base_quantity?.unit,
              item_list_id: undefined,
              item_list_name: undefined,
              item_variant: undefined,
              location_id: "Shop",
              price: parseFloat(
                product.pricing[0]?.price_per_base_quantity.amount
              ),
              quantity: 1,
            },
          ],
        },
      });
    },
  },
};
</script>
