// This binds the application level global events to the global event bus which is initialized in bootstrap.js

import product from './mixins/product';

const app = window.app;

window.appVariables.bus.$on('cart-items-added', (cartItem) => {
  app.cart = cartItem.cart;
  app.itemJustAddedToCart.product = cartItem.product;
  app.itemJustAddedToCart.product.quantityNotice = false;
  app.itemJustAddedToCart.product.minMaxQuantity = product.methods.ProductMinMaxQuantity(cartItem.product);
  app.data = cartItem.data;
  app.multipleItems = cartItem.multipleItems;
  app.swatchItem = cartItem.swatchItem;

  const isCartPage = window.location.pathname.startsWith('/cart');

  if (cartItem.showBanner && !isCartPage) {
    app.showCartBanner();
  }

});

window.appVariables.bus.$on('cart-updated', (cart) => {
  app.cart = cart;
});
