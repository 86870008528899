<template>
  <div id="navbar-subheader">
    <div class="box">
      <span>
        <button class="btn btn-default" @click="showPanel">
          <i class="fa fa-bars fa-2x" aria-hidden="true"></i>
          <span>&nbsp;Help</span>
        </button>
      </span>
    </div>
    <div class="box">
      <ul class="nav navbar-nav">
        <li class="nav-item dropdown dropdown-large" @mouseover="megaMenuOpen = true" @mouseleave="megaMenuOpen = false">
          <a href="/shop/powder-coating-colors" class="dropdown-toggle nav-link" role="button" aria-haspopup="true"
            aria-expanded="false">Shop&nbsp;<i class="fa fa-chevron-down" aria-hidden="true"></i></a>
          <ul class="dropdown-menu dropdown-menu-large row" @mouseleave="megaMenuOpen = false">
            <div class="text-center">
              <a href="/coverage-calculator">
                <h4 class="well title-two-tone"><i class="fa fa-calculator" aria-hidden="true"></i>&nbsp;Coverage
                  &nbsp;<span>Calculator</span>
                </h4>
              </a>
            </div>
            <h2 class="nav-header">
              <a class="title-text" href="/shop/powder-coating-colors">Powder Coating and Supplies</a>
              <a href="/shop/powder-coating-colors">
                <small>&nbsp;Shop All&nbsp;
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </small>
              </a>
            </h2>
            <li class="col-sm-3">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/powder-coating-colors">Powders</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_finish_metal">Metallic</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_finish_solid">Solid</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_finish_texture">Texture</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_finish_transparent">Transparent</a></li>
                <li><a class="see-all" href="/shop/powder-coating-colors">See All</a></li>
              </ul>
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/powder-coating-swatch-sets">Swatch Sets</a></li>
                <li><a href="/shop/powder-coating-swatch-sets?q=metallic">Architectural Metallics</a></li>
                <li><a href="/shop/powder-coating-swatch-sets?q=casper">Casper</a></li>
                <li><a href="/shop/powder-coating-swatch-sets?q=color%20shift">Color Shifting</a></li>
                <li><a href="/shop/powder-coating-swatch-sets?q=designer">Designer</a></li>
                <li><a class="see-all" href="/shop/powder-coating-swatch-sets">See All</a></li>
              </ul>
            </li>
            <li class="col-sm-3">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/powder-coating-collections">Collections</a>
                </li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_automotive">Automotive</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_designer">Designer</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_nfl">NFL</a></li>
                <li><a href="/shop/powder-coating-colors?finishes=pris_powersports">Powersports</a></li>
                <li><a class="see-all" href="/shop/powder-coating-collections">See All</a></li>
              </ul>
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/powder-coating-colors?finishes=pris_equipment">Equipment</a></li>
                <li><a class="see-all" href="/shop/powder-coating-colors?finishes=pris_equipment">See All</a></li>
              </ul>
            </li>
            <li class="col-sm-3">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/color-match">Color Match</a></li>
                <li><a href="/shop/color-match?search=Ford">Ford</a></li>
                <li><a href="/shop/color-match?search=Honda">Honda</a></li>
                <li><a href="/shop/color-match?search=Toyota">Toyota</a></li>
                <li><a href="/shop/color-match?search=Yamaha">Yamaha</a></li>
                <li><a class="see-all" href="/shop/color-match">See All</a></li>
              </ul>
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/gear-apparel">Apparel</a></li>
                <li><a class="see-all" href="/shop/gear-apparel">See All</a></li>
              </ul>
            </li>
            <li class="col-sm-3">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/shop/powder-coating-creations">Custom Creations</a>
                </li>
                <li><a class="see-all" href="/shop/powder-coating-creations">See All</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a href="/shop/color-match" class="nav-link">Color Match</a>
        </li>
        <!-- <li class="nav-item dropdown dropdown-large" @mouseover="megaMenuOpen = true" @mouseleave="megaMenuOpen = false">
          <a href="/find-applicator" class="dropdown-toggle nav-link" role="button"
            aria-haspopup="true" aria-expanded="false">Applicators&nbsp;<i class="fa fa-chevron-down"
              aria-hidden="true"></i></a>
          <ul class="dropdown-menu dropdown-menu-large row" @mouseleave="megaMenuOpen = false">
            <p class="nav-desc">
              Applicators are people/businesses that can help apply {{ $root.domainName }} to whatever parts or projects
              you
              can think of. Use our applicator locator to find shops nearby.
            </p>
            <h2 class="nav-header">
              <a class="title-text" href="/find-applicator">{{ $root.domainName }} Applicators</a>
              <a href="/find-applicator">
                <small>&nbsp;See All&nbsp;
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </small>
              </a>
            </h2>
            <li class="col-sm-4">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/find-applicator">Find A {{ $root.domainName }} Powder Coater
                    Nearby</a></li>
                <li><a href="/find-applicator?q=houston">Houston</a></li>
                <li><a href="/find-applicator?q=seattle">Seattle</a></li>
                <li><a href="/find-applicator?q=las+vegas">Las Vegas</a></li>
                <li><a href="/find-applicator?q=los+angeles">Los Angeles</a></li>
                <li><a href="/find-applicator?q=miami">Miami</a></li>
                <li><a href="/find-applicator?q=new+york">New York</a></li>
                <li><a href="#">Start Searching...</a></li>
              </ul>
            </li>
            <li class="col-sm-4">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/location">Our International Distributors</a></li>
                <li><a href="/australia">Australia</a></li>
                <li><a href="/colombia">Colombia</a></li>
                <li><a href="/deutschland-pulverbeschichtung">Germany</a></li>
                <li><a href="/hong-kong">Hong Kong</a></li>
                <li><a href="/japan">Japan</a></li>
                <li><a href="/south-korea">South Korea</a></li>
                <li><a href="/uk">United Kingdom</a></li>
              </ul>
            </li>
            <li class="col-sm-4">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="#">Top Applicators</a></li>
                <li><a href="#">California Chrome Wheel Inc.</a></li>
                <li><a href="#">Crown Customs</a></li>
                <li><a href="#">Mac Powder Coating</a></li>
                <li><a href="#">Triangle Coating Inc.</a></li>
                <li><a href="#">Unit F14 Powder Coating</a></li>
                <li><a href="#">More...</a></li>
              </ul>
            </li>
          </ul>
        </li> -->
        <li class="nav-item dropdown dropdown-large" @mouseover="megaMenuOpen = true" @mouseleave="megaMenuOpen = false">
          <a href="/gallery" class="dropdown-toggle nav-link" role="button" aria-haspopup="true"
            aria-expanded="false">Gallery&nbsp;<i class="fa fa-chevron-down" aria-hidden="true"></i></a>
          <ul class="dropdown-menu dropdown-menu-large row" @mouseleave="megaMenuOpen = false">
            <!-- <p class="nav-desc">
              Browse the work of fellow powder coating enthusiasts, applicators, and businesses to inspire your next
              project!
            </p> -->
            <h2 class="nav-header">
              <a class="title-text" href="/gallery">Project Gallery</a>
              <a href="/gallery">
                <small>&nbsp;See All&nbsp;
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </small>
              </a>
            </h2>
            <li class="col-sm-6">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/gallery?tab=top-searches">Top Gallery Searches</a></li>
                <li><a href="/gallery?htids=Wheels">Wheels</a></li>
                <li><a href="/gallery?htids=Brake%20Calipers">Brake Calipers</a></li>
                <li><a href="/gallery?htids=Bicycles">Bicycles</a></li>
                <li><a href="/gallery?htids=Suspension">Suspension</a></li>
                <li><a href="/gallery?htids=Motorcycles">Motorcycles</a></li>
                <li><a class="see-all" href="/gallery">See All</a></li>
              </ul>
            </li>
            <li class="col-sm-6">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/gallery">See Projects By Color</a></li>
                <li><a href="/gallery?color=pris_blue">Blue</a></li>
                <li><a href="/gallery?color=pris_red">Red</a></li>
                <li><a href="/gallery?color=pris_black">Black</a></li>
                <li><a href="/gallery?color=pris_green">Green</a></li>
                <li><a href="/gallery?color=pris_purple">Purple</a></li>
                <li><a class="see-all" href="/gallery">See All</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a href="/find-applicator" class="nav-link">Applicators</a>
        </li>
        <li class="nav-item dropdown dropdown-large" @mouseover="megaMenuOpen = true" @mouseleave="megaMenuOpen = false">
          <a href="/powder-coating-industries" class="dropdown-toggle nav-link" role="button" aria-haspopup="true"
            aria-expanded="false">Industries&nbsp;<i class="fa fa-chevron-down" aria-hidden="true"></i></a>
          <ul class="dropdown-menu dropdown-menu-large row" @mouseleave="megaMenuOpen = false">
            <h2 class="nav-header">
              <a class="title-text" href="/powder-coating-industries">Powder Coating Industries</a>
              <a href="/powder-coating-industries">
                <small>&nbsp;See All&nbsp;
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </small>
              </a>
            </h2>
            <li class="col-sm-6">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/powder-coating-industries">Industries</a></li>
                <li><a href="/powder-coating-industries/architectural-and-design">Architectural & Design</a></li>
                <li><a href="/powder-coating-industries/audio">Audio & Music</a></li>
                <li><a href="/powder-coating-industries/automotive">Automotive</a></li>
                <li><a href="/powder-coating-industries/industrial">Industrial</a></li>
                <li><a href="/powder-coating-industries/lighting">Lighting</a></li>
                <li><a href="/powder-coating-industries/retail">Retail</a></li>
                <li><a href="/powder-coating-industries/wheels">Wheels</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown dropdown-large" @mouseover="megaMenuOpen = true" @mouseleave="megaMenuOpen = false">
          <a href="/knowledge-base" class="dropdown-toggle nav-link" role="button" aria-haspopup="true"
            aria-expanded="false">Resources&nbsp;<i class="fa fa-chevron-down" aria-hidden="true"></i></a>
          <ul class="dropdown-menu dropdown-menu-large row" @mouseleave="megaMenuOpen = false">
            <!-- <p class="nav-desc">
              Need some help? Our collection of articles, documents, videos will aid you with your powder coating
              projects.
            </p> -->
            <h2 class="nav-header">
              <a class="title-text" href="/knowledge-base">Resources</a>
              <a href="/knowledge-base">
                <small>&nbsp;See All&nbsp;
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </small>
              </a>
            </h2>
            <!-- <li class="col-sm-6">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/knowledge-base">Popular Knowledge Base Articles</a></li>
                <li><a href="/knowledge-base/141/color-matching-how-do-i-color-match-my-item">How Do I Color Match?</a>
                </li>
                <li><a href="/knowledge-base/15/how-do-you-know-if-a-clear-coat-is-required">How Do I Know If I Need A
                    Clear Coat</a></li>
                <li><a href="/knowledge-base/32/is-a-primer-necessary-under-powder-coating">Is A Primer Necessary?</a>
                </li>
                <li><a href="/knowledge-base/36/how-much-heat-can-powder-coating-withstand">How Much Heat Can Powder
                    Coating Withstand?</a></li>
                <li><a href="/knowledge-base/21/can-i-touch-up-powder-coating-is-there-touch-up-paint">Can I Touch Up
                    Powder Coating?</a></li>
                <li><a href="/knowledge-base">See All</a></li>
              </ul>
            </li> -->
            <li class="col-sm-6">
              <ul class="nav-group">
                <li class="dropdown-header"><a href="/knowledge-base">Resources</a></li>
                <li><a href="/coverage-calculator">Coverage Calculator</a></li>
                <li><a href="/documents">Documents</a></li>
                <li><a href="/knowledge-base">Knowledge Base</a></li>
                <li><a href="/videos">Videos</a></li>
                <li><a href="https://forms.monday.com/forms/d4b8040d38e7786a9117f76398b42c13?r=use1">Become an Applicator</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a href="/location" class="nav-link">International</a>
        </li>
        <!-- <li class="nav-item">
          <a href="/todays-deals" id="sale-link" class="nav-link">
            Sale&nbsp;<i class="fa fa-exclamation" aria-hidden="true"></i>
          </a>
        </li> -->
        <div class="backdrop" v-show="megaMenuOpen"></div>
      </ul>
    </div>
    <div class="box country-cta">
      <span>
        <img src="/img/prismatic-usa.svg" alt="US Flag">&nbsp;Proudly Made in the USA
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'navigation',

  data() {
    return {
      megaMenuOpen: false,
    }
  },

  methods: {
    showPanel() {
      this.$emit('open-menu', true);
    },
  }
}
</script>

<style scoped lang="scss">
#navbar-subheader {
  display: flex;
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  height: 55px;
  font-family: 'Helvetica', 'Open Sans';

  .country-cta {
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: .5rem;
    font-weight: 600;

    img {
      filter: grayscale(1);
      height: 15px;
      max-height: 15px;
      margin-bottom: 2px;
    }
  }

  .box {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;

    .navbar-nav {
      height: 100%;
    }

    .btn-default {
      .fa-bars {
        text-align: center;
        vertical-align: middle;
      }

      span {
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    .backdrop {
      position: fixed;
      background-color: rgba(0, 0, 0, .5);
      height: 100vh;
      width: 100vw;
      left: 0;
      top: 149px;
      color: inherit;
    }

    .dropdown-large {
      position: static !important;
    }


    .nav-item {
      height: 100%;

      .nav-link {
        color: #393939;
        text-transform: uppercase;
        background-color: transparent;
        font-size: clamp(12px, 1.0vw, 18px);

        i {
          font-size: 10px;
        }
      }

      #sale-link {
        color: #3982c4;
      }
    }

    .nav-item:hover {
      background-color: #3982c4;
      height: 100%;

      .nav-link {
        color: #fff !important;
        height: 100%;
      }
    }

    .dropdown-menu-large {
      margin-left: -1px;
      margin-right: -1px;
      margin-top: -1px;
      padding: 1% 20% 2% 20%;
      left: 0;
      right: 0;
      overflow: hidden;

      .nav-desc,
      .nav-header {
        margin-bottom: 2%;

        .title-text {
          padding-bottom: 1px;
          border-bottom: 2px solid;
          text-transform: uppercase;
        }

        a {
          font-size: inherit;
          margin: inherit;
        }
      }

      .nav-group {
        padding-bottom: 15px;

        .dropdown-header {
          color: #428bca;
          font-size: inherit;
          padding: 0;

          a {
            padding: 0;
            font-weight: 600;
            font-size: 16px;
          }

          .divider {
            margin: 5px 0;
          }
        }
      }
    }

    .dropdown-menu-large>li>ul {
      padding: 0;
      margin: 0;
    }

    .dropdown-menu-large>li>ul>li {
      list-style: none;
    }

    .dropdown-menu-large>li>ul>li>a {
      display: block;
      padding: 3px 20px;
      clear: both;
      line-height: 1.428571429;
      color: #333333;
      white-space: normal;
      width: fit-content;
    }

    .dropdown-menu-large>li ul>li>a:hover,
    .dropdown-menu-large>li ul>li>a:focus {
      text-decoration: none;
      color: #262626;
      background-color: #f5f5f5;
      width: fit-content;
      border-radius: 4px;
      font-weight: 600;
    }

    .see-all {
      font-size: smaller;
      font-weight: 600 !important;
      font-style: italic;
    }
  }

  .box:first-child>span {
    margin-right: auto;
  }

  .box:last-child>span {
    margin-left: auto;
  }
}

@media (max-width: 1400px) {
  #navbar-subheader {
    padding: 0 2%;

    .box {
      .dropdown-menu-large {
        padding: 1% 5% 2% 5%;
      }
    }
  }
}
</style>
