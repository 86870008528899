/**
  * @file modal-notifications.js
  * @summary a helper mixin to reduce the duplication of notification modals.
  * @alias modal-notifications
 */

export default {
  data() {
    return {
      lockedTitle: "",
      lockedMessage: "",
      okCancel: false,
      okCancelInput: false,
      okCancelText: [],
      okCancelAction: "",
      cancelFunction: "",
      okCancelProduct: {},
      okCancelQuantity: 0,
    };
  },

  methods: {
    hasTooManyItems() {
      this.$root.lockedTitle = this.lockedTitle = "Unable to Add Swatch";
      this.$root.lockedMessage = this.lockedMessage = "There is a limit of 50 items that can be added to the cart when not logged in.";
      this.okCancel = false;

      $("#informationModal").modal("show");
    },

    errorModal(errTitle, errMsg) {
      this.$root.lockedTitle = this.lockedTitle = errTitle;
      this.$root.lockedMessage = this.lockedMessage = errMsg;
      this.okCancel = false;

      $("#informationModal").modal("show");
    },

    qtyUnavailable(
      title,
      product,
      cancelProduct,
      actionType,
      cancelInput,
      amountEntered,
      cancelText,
      cancelFunc
    ) {
      const defaultMsg = "Can't wait? Please call us at 1-866-774-7628 and let us help you pick the perfect color alternative from our massive color library. If you'd like to continue with placing your order, please select “yes” and the color will remain on backorder status until raw materials are received and the order can be completed.";
      const notifyMsg = "Click <b>Notify Me</b> to be notified when product(s) are back in stock. By selecting <b>Notify Me</b>, your out of stock item(s) will be removed. Can't wait? Please call us at 1-866-774-7628 and let us help you pick the perfect color alternative from our massive color library. Otherwise, keep checking back as our inventory gets updated daily.";
      const ifNoLogin = "If you would like to be notified when this product is back in stock via Email, please Log In with your account and try again, OR enter and submit your email below. Can't wait? Please call us at 1-866-774-7628 and let us help you pick the perfect color alternative from our massive color library.";

      /**
       * TODO: This is a pretty hacky way to force the properties to update.
       * I'm sure there's a better way to do this, but for the time being, this works.
       * There may be some unintended side effects, so be sure to test thoroughly.
       * > Sebastian Inman <sinman@nicindustries.com> 08/27/2024
       */

      this.$root.lockedTitle = this.lockedTitle = title ?? "Requested Product Quantity Unavailable";
      this.$root.lockedMessage = this.lockedMessage = `You have great taste! Unfortunately someone else has already ordered the available inventory for ${product.name} (${product.sku}). ${actionType === "subscribe" ? notifyMsg : actionType === 'subscribeManual' ? ifNoLogin: defaultMsg}`;
      this.$root.okCancel = this.okCancel = true;
      this.$root.okCancelInput = this.okCancelInput = cancelInput ?? false;
      this.$root.cancelFunction = this.cancelFunction = cancelFunc ?? "";
      this.$root.okCancelText = this.okCancelText = cancelText ?? ["Yes", "No"];
      this.$root.okCancelAction = this.okCancelAction = actionType;
      this.$root.okCancelProduct = this.okCancelProduct = cancelProduct ?? {};
      this.$root.okCancelQuantity = this.okCancelQuantity = amountEntered;

      $("#informationModal").modal("show");
    },

    subscription(title, msg) {
      this.$root.lockedTitle = this.lockedTitle = title;
      this.$root.lockedMessage = this.lockedMessage = msg;
      this.okCancel = false;
      this.okCancelInput = false;

      setTimeout(() => {
        $("#informationModal").modal("show");
      }, 500);
    },

    clearModal() {
      this.$root.lockedTitle = this.lockedTitle = "";
      this.$root.lockedMessage = this.lockedMessage = "";
      this.okCancel = false;
      this.okCancelInput = false;
      this.okCancelText = [];
      this.okCancelAction = "";
      this.cancelFunction = "";
      this.okCancelProduct = {};
    }
  },
};
