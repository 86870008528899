<template>
  <section id="featured-products" class="row">
    <div class="text-center">
      <h1 class="title-underline">
        Featured Colors
      </h1>
    </div>
    <div class="search-main">
      <form action="">
        <div class="search-box">
          <input type="text" name="global_search" id="homePageSearch" class="global-search" v-model="searchText"
            placeholder="Search by color" />
          <button class="btn-search" @click.prevent="searchFeed()" :disabled="!searchText.length">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </form>
    </div>

    <div class="product-wrap">
      <div class="row">
        <div class="product-cards custom-product-cards">
          <Product
            v-for="(product, index) in featured_products"
            class="custom-product-card"
            :key="product.id"
            :product="product"
            :index="index"
            :orderFreeSwatchBtn="true"
            :isSearchPage="false"
            @add-swatch-to-cart="addSwatchToCart"
          />
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import Product from "../../../../shop/products/Product.vue";

export default {
  name: 'featured-products',

  components: {
    Product
  },

  data() {
    return {
      featured_products: window._PR?.featured_products || [],
      searchText: ''
    }
  },

  methods: {
    searchFeed() {
      let shopUrl = `/shop/powder-coating-colors?q=${this.searchText}`
      window.location = shopUrl;
    },
    addSwatchToCart(swatch) {
      this.$emit("add-swatch-to-cart", swatch);
    }
  }
}
</script>

<style scoped lang="scss">
#featured-products {
  padding: 0 5% 5% 5%;

  .title-underline {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  .product-wrap {
    padding: 15px;

    .custom-product-cards {
      justify-content: center;
      flex-wrap: wrap;
      display: flex;

      .custom-product-card {
        flex: 0 0 calc(25%);
      }
    }

    #loading-products {
      font-size: 60px;
      text-align: center;
      position: relative;
      height: 10vh;
    }

    @media screen and (max-width: 768px) {
      .custom-product-cards {
        display: grid;
      }
    }
  }
}
</style>
