<template>
  <section id="featured-brands" class="row text-center">
    <div id="featured-brands-carousel" class="carousel slide" data-ride="carousel">
      <h1 id="heading" class="fade-in title-underline">{{ brandGroupName }}</h1>
      <!-- Carousel items -->
      <div class="carousel-inner">
        <div v-for="(slide, index) in icons" :id="index" class="item" :key="index"
          :class="{ 'active': index === 'automotive' }">
          <div class="row brand-group">
            <div class="brand well" v-for="(brand, index) in slide" :key="index">
              <img class="center-block" loading="lazy" :src="brand.logo" :alt="brand.alt" width="150" height="150">
            </div>
          </div>
        </div>
        <div class="col-sm-12 all-brands">
          <a href="/brands" class="btn btn-black">View All Brands</a>
        </div>
      </div>
      <!-- Controls -->
      <a class="left carousel-control" href="#featured-brands-carousel" role="button" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#featured-brands-carousel" role="button" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>

<script>
import brandIcons from '../../../../../../data/brandIcons.json'

export default {
  name: 'featured-brands',

  data() {
    return {
      icons: brandIcons.featuredBrands[0] || {},
      brandGroupName: 'Brands That Trust Prismatic Powders',
      // brandGroupName: 'automotive'
    }
  },

  mounted() {
    // 👇 the following allowed for dynamic labels for each brand group. Uncomment and set brandGroupName = automotive to enable. (Marketing requested that it be static).

    // let vm = this;

    // $('#featured-brands-carousel').on('slid.bs.carousel', function () {
    //   let carouselData = $(this).data('bs.carousel');
    //   let name = carouselData.$element.find('.item.active')[0].id;

    //   carouselData.$element.find('.title-underline').removeClass('fade-in');
    //   carouselData.$element.find('.title-underline').width();
    //   carouselData.$element.find('.title-underline').addClass('fade-in');

    //   vm.brandGroupName = name;
    // });
  }
}
</script>

<style scoped lang="scss">
#featured-brands {
  margin-bottom: 3%;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: .6s;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  #featured-brands-carousel {
    margin-top: 20px;
    background: #4b6cb7;
    background: linear-gradient(to right, #182848, #4b6cb7);
    padding: 5% 0;

    .title-underline {
      color: #fafafa;
    }

    .all-brands {
      padding-bottom: 2%;
    }

    .brand-group {
      display: block;
      justify-content: center;
      padding: 3% 0;

      .brand {
        display: inline-block;
        margin: 0 2%;
      }

      .well {
        padding: 0;
      }

      img {
        border-radius: 5%;
        height: 150px;
      }
    }
  }
}
</style>
