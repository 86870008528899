<template>
  <div id="notification-bar" class="text-center">
    <p v-if="$root.hasSalesPromo">
      <a :href="salesUrl">
        {{ notificationMsg }}
      </a>
    </p>
    <p v-else>{{ notificationMsg }}</p>
  </div>
</template>

<script>
export default {
  name: 'notification-bar',

  computed: {
    notificationMsg() {
      if (this.$root.notifications.length > 0) {
        return this.$root.notifications[0].message;
      }

      return 'Have a Wonderful Day!';
    },

    salesUrl() {
      if (this.$root.notifications[0].target_url.length > 0) {
        return this.$root.notifications[0].target_url;
      }

      return '/todays-deals';
    }
  }
}
</script>

<style scoped lang="scss">
#notification-bar {
  background-color: #3982c4;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 800;
  height: 25px;
  line-height: 25px;

  p {
    margin: 0;

    a {
      color: #fff;
    }

    a:hover {
      color: #fff !important;
    }
  }
}
</style>
