import autoload from "./autoload";
import auth from "./mixins/auth";
import cart from "./mixins/cart";
import initDom from "./mixins/init_dom";
import NotificationPopup from "./components/modals/NotificationPopup";
import HomeLayout from "./components/site_pages/pages/home/HomeLayout";

autoload();

window.app = new Vue({
  el: "#vue-home",

  mixins: [initDom, auth, cart],

  components: {
    NotificationPopup,
    HomeLayout,
  },

  data() {
    return {
      popup: window._PR?.popup || null,
      lockedTitle: "",
      lockedMessage: "",
    };
  },

  mounted() {
    if (this.popup !== null) {
      $("#notificationPopup").modal("show");
    }
  },

  methods: {
    openLoginModal() {
      $("#logInLink").click();
    },
  },
});

// displays the add-to-cart dropdown
require("./app_events");
