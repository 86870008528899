<template>
  <transition name="fade" mode="out-in">
    <section id="featured-hero-banner" class="row" @click="carouselOnClick">
      <div class="banner">
        <div id="home-carousel" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#home-carousel" :class="{ 'active': index === 0 }" v-for="(banner, index) in banners"
              :key="index" v-if="banners.length" :data-slide-to="index"></li>
          </ol>
          <div class="carousel-inner">
            <div v-for="(banner, index) in banners" :id="`slide-${index}`" class="item" :key="index"
              :class="{ 'active': index === 0, 'no-overlay': !banner.display_overlay }">
              <div v-if="banner.video" style="height: 100%;">
                <video autoplay loop muted width="auto" height="auto">
                  <source :src="banner.video" type="video/mp4" />
                </video>
              </div>
              <div v-else-if="banner.display_overlay && !banner.video" :id="bannerSlideLabel(banner.title)">
                <img :src="`${banner.header_image_url}?size=1725`" :alt="`${banner.title} Banner`"
                  v-if="($mq === 'desktop' || (($mq === '1199' || $mq === '991') && !banner.tablet_image_url) || (($mq === '767' || $mq === '600' || $mq === '479') && !banner.mobile_image_url))">
                <img :src="`${banner.tablet_image_url}?size=1200`" :alt="`${banner.title} Banner`"
                  v-if="($mq === '1199' || $mq === '991') && banner.tablet_image_url">
                <img :src="`${banner.mobile_image_url}?size=800`" :alt="`${banner.title} Banner`"
                  v-if="($mq === '767' || $mq === '600' || $mq === '479') && banner.mobile_image_url">
                <div class="container">
                  <div class="carousel-caption">
                    <div class="caption-inner">
                      <h2>{{ banner.title }}</h2>
                      <p v-html="banner.body"></p>
                      <a :href="banner.cta_button_link" class="btn btn-bordered" v-if="banner.cta_button_text && banner.cta_button_link">
                        {{ banner.cta_button_text }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="!banner.display_overlay && !banner.video" :id="bannerSlideLabel(banner.title)">
                <a :href="banner.cta_button_link" v-if="banner.cta_button_link">
                  <img :src="`${banner.header_image_url}?size=1725`" :alt="`${banner.title} Banner`"
                    v-if="($mq === 'desktop' || (($mq === '1199' || $mq === '991') && !banner.tablet_image_url) || (($mq === '767' || $mq === '600' || $mq === '479') && !banner.mobile_image_url))">
                  <img :src="`${banner.tablet_image_url}?size=1200`" :alt="`${banner.title} Banner`"
                    v-if="($mq === '1199' || $mq === '991') && banner.tablet_image_url">
                  <img :src="`${banner.mobile_image_url}?size=800`" :alt="`${banner.title} Banner`"
                    v-if="($mq === '767' || $mq === '600' || $mq === '479') && banner.mobile_image_url">
                </a>
                <span v-else>
                  <img :src="`${banner.header_image_url}?size=1725`" :alt="`${banner.title} Banner`"
                    v-if="($mq === 'desktop' || (($mq === '1199' || $mq === '991') && !banner.tablet_image_url) || (($mq === '767' || $mq === '600' || $mq === '479') && !banner.mobile_image_url))">
                  <img :src="`${banner.tablet_image_url}?size=1200`" :alt="`${banner.title} Banner`"
                    v-if="($mq === '1199' || $mq === '991') && banner.tablet_image_url">
                  <img :src="`${banner.mobile_image_url}?size=800`" :alt="`${banner.title} Banner`"
                    v-if="($mq === '767' || $mq === '600' || $mq === '479') && banner.mobile_image_url">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>

export default {
  name: 'featured-hero-banner',

  data() {
    return {
      banners: window._PR?.banners || [],
      videoFilePath: '',
    }
  },

  async created() {
    this.sendDataLayerSlideEvent('slide-0', this.bannerSlideLabel(this.banners[0].title));
  },

  mounted() {
    let vm = this;

    $('#home-carousel').on('slid.bs.carousel', function () {
      const currentSlideIndex = $('div.active').index();
      const slideId = `slide-${currentSlideIndex}`;
      const slideContents = document.getElementById(slideId).children[0].id;

      vm.sendDataLayerSlideEvent(slideId, slideContents);
    });
  },

  methods: {
    // Assign a label to the slide for tracking purposes
    bannerSlideLabel(label) {
      if (label !== null && label !== undefined && label !== "") {
        let newLabel = label.replace(/ /g, '-').toLowerCase();

        return newLabel;
      }

      return newLabel = "video";
    },

    sendDataLayerSlideEvent(slideId, slideContents) {
      window.dataLayer.push({
        'event': 'homepage-hero',
        'category': slideId,
        'label': slideContents
      });
    },

    carouselOnClick(event) {
      const currentSlideIndex = $('div.active').index();
      const currentSlideButtonLink = this.banners[currentSlideIndex].cta_button_link;
      // Verify that the link isn't null and that the user is not clicking on a carousel indicator
      if (currentSlideButtonLink && event.target.tagName.toLowerCase() !== "li" && !event.target.parentElement.classList.contains("carousel-indicators")) {
        window.location.href = currentSlideButtonLink;
      }
    }
  }
}
</script>

<style scoped lang="scss">
#featured-hero-banner {

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  .placeholder {
    .img-placeholder {
      height: 45vh;

      i {
        color: #a9a9a9;
        font-size: 8em;
        transform: translate(0%, 15vh);
      }
    }
  }

  .item {

    &:has(a[href]) {
      cursor: pointer;
    }

    video {
      width: 100%;
      object-fit: cover;
      height: inherit;
    }

    img {
      height: 45vh;
    }
  }

  .carousel-indicators {
    pointer-events: none;
    li {
      pointer-events: all;
      border-color: #FFF;
      &::before {
        background-color: #FFF;
      }
    }
  }

}
</style>
