<template>
  <div id="menu-panel" class="col-sm-12">
    <div class="row panel-heading">
      <h4 class="col-sm-8">Help Menu</h4>
      <button class="btn btn-link col-sm-offset-2 col-sm-2" data-toggle="drawer" data-target="#menu-panel"
        @click="closeMenu">
        <i class="fa fa-times fa-lg" aria-hidden="true"></i>
      </button>
    </div>
    <h4>Discover</h4>
    <div class="row list-group">
      <a href="/testimonials" class="list-group-item">Why {{ $root.domainName }}</a>
      <a href="/location" class="list-group-item">International Shoppers</a>
      <a href="/find-applicator" class="list-group-item">Find Someone To Powder Coat Your Parts</a>
      <a href="/gallery" class="list-group-item">Get Ideas And Find Inspiration</a>
      <a href="https://forms.monday.com/forms/d4b8040d38e7786a9117f76398b42c13?r=use1" target="_blank"
        class="list-group-item">Become A {{ $root.domainName }} Applicator</a>
    </div>
    <hr class="row">
    <h4>Help</h4>
    <div class="row list-group">
      <a href="/about-us" class="list-group-item">About Us</a>
      <a href="/contact-us" class="list-group-item">Contact Us</a>
      <a href="tel:1-866-774-7628" title="1-866-774-7628" class="list-group-item">
        Call Us&nbsp;
        <small>
          <h6 style="display: inline;">1-866-774-7628</h6>
        </small>
      </a>
      <a href="mailto:info@prismaticpowders.com" title="info@prismaticpowders.com" class="list-group-item">Email Us</a>
      <a href="/terms-and-policies?to=returns" class="list-group-item">Returns</a>
    </div>
    <hr class="row">
    <h4>Account</h4>
    <div class="row list-group">
      <div v-if="$root.isLoggedIn">
        <a href="/my-account" class="list-group-item">Your Account</a>
        <a href="/my-page" class="list-group-item">Edit Your Applicator Page</a>
        <a href="/order-history" class="list-group-item">Your Orders</a>
        <a href="/wishlist" class="list-group-item">Product Wishlist</a>
        <a href="/favorite-resources" class="list-group-item">Favorite Resources</a>
        <a href="/gallery?bookmarked=true" class="list-group-item">Bookmarked Gallery</a>
      </div>
      <div class="login-btn" v-else>
        <button class="btn btn-black btn-block" @click="openLoginModal()">
          <i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;Log In
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'menu-panel',

  methods: {
    closeMenu() {
      this.$parent.$parent.$emit('close-menu', false);
    },

    openLoginModal() {
      $("#logInLink").click();
    },
  }
}
</script>

<style scoped lang="scss">
#menu-panel {
  hr {
    border-top: 1px solid #393939;
  }

  .list-group-item:first-child {
    border-top: none;
  }

  .list-group-item:last-child {
    border-bottom: none;
  }

  .panel-heading {
    color: #fff;
    background-color: #393939;
    border-radius: unset;

    button:hover {
      color: #fff;
    }
  }

  .list-group {

    .login-btn {
      margin: 3%;
    }

    .list-group-item {
      border-radius: unset;
    }
  }
}
</style>
